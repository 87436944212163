<template>
  <div class="wrapper">
    <img src="/img/Kolumbusoffpath.jpg" :alt="alt" :title="alt" class="image-responsive" />
  </div>
</template>

<script>
export default {
  name: 'OffPath',
  props: {
    alt: {
      type: String,
      default: 'Endring i kjøremønster',
    },
  },
}
</script>

<style scoped>
.wrapper {
  background-color: #121212;
  height: 100%;
}

.image-responsive {
  max-width: 100%;
  height: auto;
}
</style>
