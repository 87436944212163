import { LogLevel } from '@microsoft/signalr'
import Vue from 'vue'

function mapLogLevel(logLevel) {
  switch (logLevel) {
    case LogLevel.Trace:
    case LogLevel.Debug:
      return 0
    case LogLevel.Information:
      return 1
    case LogLevel.Warning:
      return 2
    case LogLevel.Error:
      return 3
    case LogLevel.Critical:
      return 4
    default:
      return undefined
  }
}

export const logger = {
  /*
  *   the minimum log level of the debugger, messages logged below this level are discarded
  * */
  logLevel: LogLevel.Debug,
  __getProperties(extraProperties = {}) {
    return {
      mac: sessionStorage.getItem('mac'),
      vehicleId: localStorage.getItem('vehicleId'),
      tripId: sessionStorage.getItem('tripId'),
      ...extraProperties,
    }
  },
  __getConsoleProperties(properties= {}) {
    return {
      isOnline: sessionStorage.getItem('isOnline'),
      isOnlineChanged: sessionStorage.getItem('isOnlineChanged'),
      isOnlineChangedReason: sessionStorage.getItem('isOnlineChangedReason'),
      ...properties,
    }
  },
  /**
   * Only logs to console
   * @param message
   * @param extraProperties to be added as structured metadata to the trace message
   */
  trace(message, extraProperties) {
    console.debug(message, logger.__getConsoleProperties(logger.__getProperties(extraProperties)))
  },
  /**
   * Logs trace with severity level debug to application insight
   * @param message
   * @param extraProperties to be added as structured metadata to the trace message
   */
  debug(message, extraProperties) {
    logger.log(LogLevel.Debug, message, extraProperties)
  },
  /**
   * Logs trace with severity level information to application insight
   * @param message
   * @param extraProperties to be added as structured metadata to the trace message
   */
  info(message, extraProperties) {
    logger.log(LogLevel.Information, message, extraProperties)
  },
  /**
   * Logs trace with severity level warning to application insight
   * @param message
   * @param extraProperties to be added as structured metadata to the trace message
   */
  warn(message, extraProperties) {
    logger.log(LogLevel.Warning, message, extraProperties)
  },
  /**
   * Logs exception to application insight
   * @param message
   * @param error
   * @param extraProperties to be added as structured metadata to the logged exception
   */
  error(message, error, extraProperties) {
    logger.log(LogLevel.Error, error ? `${message} error: ${error}` : message, extraProperties)
  },
  log(logLevel, message, extraProperties) {
    if (logLevel >= logger.logLevel) {
      const properties = logger.__getProperties(extraProperties)
      try {
        switch (logLevel) {
          case LogLevel.Error:
          case LogLevel.Critical:
            console.error(message, logger.__getConsoleProperties(properties))
            Vue.prototype.$appInsights.trackException({ exception: new Error(message), properties })
            break
          case LogLevel.Warning:
            console.warn(message, logger.__getConsoleProperties(properties))
            Vue.prototype.$appInsights.trackTrace({ message, severityLevel: mapLogLevel(logLevel), LogLevel: logLevel, properties })
            break
          case LogLevel.Information:
          case LogLevel.Debug:
            console.log(message, logger.__getConsoleProperties(properties))
            Vue.prototype.$appInsights.trackTrace({ message, severityLevel: mapLogLevel(logLevel), LogLevel: logLevel, properties })
            break;
          default:
            console.debug(message, logger.__getConsoleProperties(properties))
            break;
        }
      } catch (error) {
        console.error(error)
      }
    }
  },
  /**
   * Logs an event to application insight
   * @param name the name of the event
   * @param extraProperties to be added as structured metadata to the logged event
   */
  event(name, extraProperties) {
    const properties = logger.__getProperties(extraProperties)
    console.log(name, logger.__getConsoleProperties(properties))
    try {
      Vue.prototype.$appInsights.trackEvent({ name, properties })
    } catch (error) {
      console.error(error)
    }
  },
  /**
   * Logs an event to application insight, but only logs it to console as a debug/verbose message
   * @param name the name of the event
   * @param extraProperties to be added as structured metadata to the logged event
   */
  traceEvent(name, extraProperties) {
    const properties = logger.__getProperties(extraProperties)
    console.debug(name, logger.__getConsoleProperties(properties))
    try {
      Vue.prototype.$appInsights.trackEvent({ name, properties })
    } catch (error) {
      console.error(error)
    }
  },
}
