import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import axios from 'axios'
import moment from 'moment-timezone'
import VueAppInsights from 'vue-application-insights'
import { signalrConnection } from '@/utils/SignalR'
import { logger } from '@/utils/logger'

// https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript#configuration
Vue.use(VueAppInsights, {
  id: process.env.VUE_APP_INSIGHT_ID,
  baseName: 'kioskVue',
  trackInitialPageView: false,
  disableAjaxTracking: true,
  enableAutoRouteTracking: true,
  router,
})

axios.interceptors.request.use(config => {
  const mac = sessionStorage.getItem('mac')
  const vehicleId = localStorage.getItem('vehicleId')
  const tripId = sessionStorage.getItem('tripId')
  config.headers['x-mac'] = mac
  config.headers['x-vehicleId'] = vehicleId
  config.headers['x-tripId'] = tripId
  const bytes = getSizeOfRequest(config)
  Vue.prototype.$appInsights.trackMetric({ name: 'kiosk-ingress', average: bytes }, { mac, vehicleId, tripId, url: config.url })
  return config
})

function getSizeOfRequest(config) {
  return config.data ? new TextEncoder().encode(JSON.stringify(config.data)).length : 0
}

axios.interceptors.response.use(response => {
  const mac = sessionStorage.getItem('mac')
  const vehicleId = localStorage.getItem('vehicleId')
  const tripId = sessionStorage.getItem('tripId')
  const bytes = getSizeOfResponse(response)
  Vue.prototype.$appInsights.trackMetric({ name: 'kiosk-egress', average: bytes }, { mac, vehicleId, tripId, url: response.config.url })
  return response
})

function getSizeOfResponse(response) {
  switch (response.config.responseType) {
    case 'blob':
      return response.data?.size ?? 0
    case 'arraybuffer':
      return response.data.byteLength
    case 'text':
      return new Blob([response.data]).size
    case 'json':
      return new TextEncoder().encode(JSON.stringify(response.data)).length
    default:
      return response.headers['content-length'] ?? 0
  }
}

// This allows us to use write this.$http instead of axios
Vue.prototype.$http = axios

moment.tz.setDefault('Europe/Oslo')
// This allows us to use this.$moment in components without importing moment/
Vue.prototype.$moment = moment

Vue.use(signalrConnection)

Vue.config.productionTip = false

Vue.config.errorHandler = async (err, vm, info) => {
  const reloadIntervalInSecOnError = 300

  try {
    await axios.post('/api/TrackCustomMetric', {
      name: 'KioskRestartExceptionHandler',
      error: err.toString(),
      stack: err.stack,
      vm: vm.$options.name,
      info,
      message: `Unhandled error occured, reloading screen in ${reloadIntervalInSecOnError} seconds`,
    })
  } catch (e) {
    logger.error(`Failed to send KioskRestartExceptionHandler error to backend`, e)
  }

  logger.error(`Unhandled error occured, reloading screen in ${reloadIntervalInSecOnError} seconds`, err, vm, info)

  setTimeout(() => {
    logger.error(`Reloading screen after unhandled error`, err, vm, info)
    location.reload()
  }, reloadIntervalInSecOnError * 1000)
};

new Vue({
  router,
  render: h => h(App),
  beforeCreate: function() {
    let telemetryInitializer = envelope => {
      envelope.tags['ai.cloud.role'] = 'Kiosk Frontend'
      envelope.tags['ai.cloud.roleInstance'] = 'Kiosk Frontend'
    }
    this.$appInsights.addTelemetryInitializer(telemetryInitializer)
  }
}).$mount('#app')
