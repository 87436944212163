<template>
  <div>
    <display-info v-if="showPlacement">
      <div> Plassering: {{ placementObj.placement ? placementObj.placement : 'Ikke satt' }}, MAC: {{ placementObj.mac }}</div>
    </display-info>
    <display-info v-if="showSoundPlayingMsg">
      <div v-if="!soundPlayException">LYD SPILLES</div>
      <div v-if="soundPlayException">
        Lyd spilling error:
        <div>{{ soundPlayException }}</div>
      </div>
    </display-info>
  </div>
</template>

<script>
import { delayWithPromise } from '@/utils/utilMisc'
import DisplayInfo from './components/DisplayInfo'
import { logger } from '@/utils/logger'

export default {
  name: 'PushNotification',
  components: { DisplayInfo },
  data() {
    return {
      showSoundPlayingMsg: false,
      soundPlayException: null,
      showPlacement: false,
      placementObj: {},
      audio: null,
    }
  },
  async created() {
    document.addEventListener('PlayTestSound',async ({ detail }) => {
      // Stop the audio if audio already is playing
      if (this.audio) {
        logger.info(`Test sound stop request received`)
        await this.stopTestSound()
      } else {
        logger.info(`Display sound play notification`)
        if (detail) {
          logger.info(`Test sound name: ${detail}`)
        }
        this.showSoundPlayingMsg = true
        this.showPlacement = false
        await this.playTestSound(detail)
      }
    })
    document.addEventListener('DisplayPlacement', async ({ detail }) => {
      logger.info('Display placement notification', { placement: detail.placement })
      this.placementObj = detail
      this.showSoundPlayingMsg = false
      this.showPlacement = true
      await delayWithPromise(9000)
      this.showPlacement = false
    })
    document.addEventListener('PushTest', async ({ detail }) => {
      logger.traceEvent('PushTest notification', { pushedTimestamp: detail.PushedTimestamp })
      await this.$http.put('/api/tracking/push-test-reply', detail)
    })
  },
  methods: {
    async playTestSound(soundName) {
      // clear previous errors
      this.soundPlayException = null

      switch (soundName) {
        case 'testSoundWhiteNoise':
          this.audio = new Audio('/test/test-sound-19-lufs-61-dba-slow-1-meter-from-source.mp3')
          break
        case 'testSoundVoice':
        default:
          this.audio = new Audio('/test/testlydfil.mp3')
      }

      this.audio.onended = async () => {
        this.showSoundPlayingMsg = false
        this.audio = null
      }

      try {
        await this.audio.play()
      } catch (error) {
        logger.error('Caught sound-play exception.', error)
        this.soundPlayException = error
        await delayWithPromise(7000)
        this.showSoundPlayingMsg = false
        this.audio = null
      }
    },
    async stopTestSound() {
      // clear previous errors
      this.soundPlayException = null

      try {
        await this.audio.pause()
        this.showSoundPlayingMsg = false
        this.audio = null
      } catch (error) {
        logger.error('Caught sound-stop exception.', error)
        this.soundPlayException = error
      }
    },
  },
}
</script>

<style scoped></style>
