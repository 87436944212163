// https://dev.to/drbragg/handling-service-worker-updates-in-your-vue-pwa-1pip
import { logger } from '@/utils/logger'
import { getWithExpiry, setWithExpiry } from '@/utils/localStorageUtils'

export default {
  data() {
    return {
      // refresh variables
      refreshing: false,
      registration: null,
    }
  },
  created() {
    // Listen for our custom event from the SW registration
    document.addEventListener('swupdatefound', this.updateAvailable, { once: true })

    // Prevent multiple refreshes
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      logger.debug('The Document associated ServiceWorkerRegistration acquired a new active worker', { refreshing: this.refreshing })
      if (this.refreshing) return
      this.refreshing = true
      // Here the actual reload of the page occurs
      logger.debug('ServiceWorker updated, performing window location reload...')
      window.location.reload()
    })
  },
  methods: {
    // Store the SW registration so we can send it a message
    // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
    // To alert the user there is an update they need to refresh for
    updateAvailable(event) {
      this.registration = event.detail
      this.refreshApp()
    },
    // Called when the user accepts the update
    refreshApp() {
      if (!this.registration?.waiting) return
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      // send message to SW to skip the waiting and activate the new SW
      logger.debug('sending message to skip waiting and activate new software.')
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
  },
}
