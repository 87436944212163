<template>
  <div class="home">
    <default-image alt="Default visning home root"></default-image>
  </div>
</template>

<script>
import DefaultImage from '../components/DefaultImage'
import { logger } from '@/utils/logger'

export default {
  name: 'Home',
  components: { DefaultImage },
  data() {
    return {}
  },
  async mounted() {
    logger.debug('Env vars: ' + JSON.stringify(process.env))
  },
}
</script>
<style scoped>
.home {
  height: 100%;
  color: whitesmoke;
}
</style>
