<template>
    <div class="content">
        <img src="/img/info-green.png" />
        <div class="notice1">Venter ved holdeplass</div>
        <div class="notice2">Bussen kjører om:</div>
    </div>
</template>

<script>
export default {
    name: "ActiveRegulation",
}
</script>

<style scoped lang="scss">
html {
    -moz-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
}

.content {
    overflow: visible;
    position: relative;
    fill: currentColor;
    white-space: nowrap;
    padding: 2.4vmin 0;
    height: 13.8vmin;
    line-height: 13.8vmin;

    img {
        height: 100%;
        padding-left: 1.5vw;
    }

    div {
        position: absolute;
        display: inline-block;
        padding-left: 2vw;
    }

    .notice1 {
        top: 0;
        font-size: 2vw;
        line-height: 10vmin;
    }

    .notice2 {
        bottom: 0;
        font-size: 4vw;
    }
}

@media (min-aspect-ratio: 30/9) {
    .content {
        div {
            padding-left: 1.5vw;
        }

        .notice1 {
            font-size: 1.35vw;
        }

        .notice2 {
            font-size: 2.5vw;
        }
    }
}
</style>