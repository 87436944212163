<template>
  <div class="marketing-container">
    <h1 v-if="initialLoad" class="init-load">
      Laster data første gang... vennligst vent.
      <span v-if="loadingProgressMsg">
        {{ loadingProgressMsg }}
      </span>
    </h1>

    <default-image v-if="defaultAltMsg !== null" :alt="defaultAltMsg"></default-image>

    <div v-if="activeTarget">
      <img v-if="activeTarget.contentType && activeTarget.contentType.startsWith('image')" class="image-responsive" alt="Kolumbus markedsinnhold" :src="activeTarget.blobUrl" />

      <video v-else-if="activeTarget.contentType && activeTarget.contentType.startsWith('video')" class="video-responsive" autoplay loop muted>
        <source :src="activeTarget.blobUrl" :type="activeTarget.contentType" />
        Sorry, your browser doesn't support embedded videos.
      </video>

      <div v-else class="iframe-container">
        <iframe :src="activeTarget.url" scrolling="no" width="100%" height="100%" sandbox allowfullscreen> </iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { delayWithPromise, makeQueryParamsString, toBoolean } from '@/utils/utilMisc'
import DefaultImage from '../../components/DefaultImage'
import networkConnectivityListener from '@/mixins/networkConnectivityListener'
import { logger } from '@/utils/logger'
import { getVehicleIdByMac } from '@/shared/common'

export default {
  name: 'MarketingContainer',
  components: { DefaultImage },
  mixins: [networkConnectivityListener],
  props: {
    mac: {
      type: String,
      default: null,
    },
    testing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      vehicleId: null,
      marketingTargets: [],
      activeTarget: null,
      loopThread: null,
      refetchThread: null,
      defaultAltMsg: null,
      initialLoad: null,
      documentTimestamp: null,
      isTestMode: null,
      loadingProgressMsg: null,
    }
  },
  async beforeRouteEnter(to, from, next) {
    if (to.params.vehicleId) {
      next(vm => {
        vm.vehicleId = to.params.vehicleId
      })
    } else if (to.params.mac) {
      try {
        const mac = to.params.mac
        const vehicleId = await getVehicleIdByMac(mac)
        if (vehicleId) {
          next(vm => {
            vm.vehicleId = vehicleId
          })
        }
      } catch (e) {
        // Error
        logger.error(`Unable to map MAC address ${to.params.mac} to vehicleId.`, e)
        next(vm => {
          vm.defaultAltMsg = `Unable to map MAC address ${to.params.mac} to vehicleId`
        })
      }
    } else {
      // Error
      logger.error('Missing vehicle or mac path parameters')
      next(vm => {
        vm.defaultAltMsg = 'Missing vehicle or mac path parameters'
      })
    }
  },
  watch: {
    vehicleId: {
      immediate: true,
      async handler() {
        this.isTestMode = toBoolean(this.$route.query.unpublished)
        if (this.vehicleId) {
          // The following essentially kick starts the showcasing
          await this.fetchData()
          this.dataChecker()
        }
      },
    },
  },
  async created() {
    await this.$signalrConnection.start(this.mac, this.testing)
  },
  beforeDestroy() {
    clearTimeout(this.loopThread)
    clearInterval(this.refetchThread)
  },
  methods: {
    dataChecker() {
      this.refetchThread = setInterval(async () => {
        await this.fetchData()
      }, 60 * 1000)
    },
    async fetchData() {
      if (this.initialLoad === null) this.initialLoad = true

      try {
        let queryPart = makeQueryParamsString({
          timestamp: this.documentTimestamp,
          test: this.isTestMode,
        })
        let response = await this.$http.get(`api/marketing-screen/vehicle/${Number(this.vehicleId)}${queryPart}`)

        if (!this.handleMarketingResponse(response)) {
          // If we get here, there is no content, and the default image is shown
          this.initialLoad = false
          return
        }

        let blobUrlsToRevoke = this.marketingTargets.map(target => target.blobUrl)

        let targetsWithBlobUrls = await this.fetchBinaryContent(response.data.targets)
        if (targetsWithBlobUrls.length === 0) {
          this.defaultAltMsg = 'Unable to load any content files'
          return
        }
        this.marketingTargets = targetsWithBlobUrls

        // Remove previous DOMStrings (object urls) to free memory
        blobUrlsToRevoke.forEach(blobUrl => URL.revokeObjectURL(blobUrl))

        this.documentTimestamp = response.data.timestamp
        this.initialLoad = false

        this.activeTarget = this.marketingTargets[0]

        if (this.loopThread === null) this.loopTargets(this.activeTarget.seconds)
      } catch (error) {
        if (this.initialLoad === true) this.initialLoad = null
        // TODO, do we need more error handling here, like show default, or are we ok since banners simply rotate happily
        // If we don't have any marketing content showing, then show default page
        if (!this.marketingTargets || this.marketingTargets.length === 0) this.defaultAltMsg = 'Fetching data about vehicle failed ' + error
        logger.error('Failed to fetch marketing content', error)
      }
    },
    async fetchBinaryContent(targets) {
      this.loadingProgressMsg = ''
      let index = 0
      for (const target of targets) {
        try {
          if (target.contentType === 'text/html') {
            index += 1
            continue
          }
          let response = await this.$http.get(`api/marketing-screen/blob/${target.id}`, {
            responseType: 'blob',
            timeout: 45000, // 45 seconds
          })
          target.blobUrl = URL.createObjectURL(response.data)
          index += 1
          this.loadingProgressMsg = index + '/' + targets.length
        } catch (error) {
          logger.error(`Unable to fetch blob for target ID: '${target.id}' URL: '${target.url}'`, error, {
            blobId: target.id,
            blobUrl: target.blobUrl,
            url: target.url,
          })
          targets.splice(index, 1)
        }
      }
      return targets
    },
    loopTargets(seconds) {
      this.loopThread = setTimeout(async () => {
        if (this.marketingTargets && this.marketingTargets.length > 0) {
          let currentIndex = this.marketingTargets.findIndex(t => t.id === this.activeTarget.id)
          let nextIndex = this.marketingTargets.length <= currentIndex + 1 ? 0 : currentIndex + 1
          // To change from one video src to another we need to do tricks. Removing current video and adding again seems to work.
          if (this.activeTarget.contentType.startsWith('video') && this.marketingTargets[nextIndex].contentType.startsWith('video')) {
            this.activeTarget = null
            await delayWithPromise(50)
          }
          this.activeTarget = this.marketingTargets[nextIndex]
        }
        this.loopTargets(this.activeTarget.seconds)
      }, seconds * 1000)
    },
    handleMarketingResponse(response) {
      if (!response.data || response.data.statusMessage || response.data.targets.length === 0) {
        if (!response.data) {
          logger.debug('Server call failed. ' + response.status)
        }
        if (response.data.statusMessage) {
          if (response.data.statusMessage === 'MARKETING_CONFIG_NOT_FOUND') {
            logger.debug('Unable to find marketing config on server')
            this.defaultAltMsg = response.data.statusMessage
          } else if (response.data.statusMessage === 'AREA_NOT_ALLOCATED') {
            logger.debug(`Vehicle ${this.vehicleId} is not allocated to an area (anbudsområde). Can't match to any content.`)
            this.defaultAltMsg = response.data.statusMessage
          } else if (response.data.statusMessage === 'VEHICLE_IDLE') {
            logger.debug(`Vehicle ${this.vehicleId} is IDLE. Can only match marketing with anbudsområde.`)
            if (response.data.targets.length === 0) this.defaultAltMsg = response.data.statusMessage
            else return true
          } else if (response.data.statusMessage === 'LATEST_VERSION') {
            logger.debug('Already have latest version of marketing content')
          } else {
            logger.debug('Unknown error message received from server ' + response.data.statusMessage)
            this.defaultAltMsg = response.data.statusMessage
          }
        } else if (response.data.targets.length === 0) {
          logger.debug(`No marketing content found for vehicle ${this.vehicleId}. Make sure there is content configured for that anbudsområde, route or trip.`)
          this.defaultAltMsg = 'No marketing content found for vehicle'
        }
        return false
      }
      return true
    },
  },
}
</script>

<style scoped>
.marketing-container {
  height: 100%;
  background-color: #121212;
  text-align: center;
}

.init-load {
  color: #f2f2f2;
  margin-top: 0;
  padding-top: 45%;
  text-align: center;
}

.iframe-container {
  overflow: hidden;
  /* Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 0.5625) */
  /*padding-top: 56.25%;*/
  /*position: relative;*/
}

.iframe-container iframe {
  border: none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.image-responsive {
  width: 100vw;
  height: 100vh;
  object-fit: contain;
}

.video-responsive {
  object-fit: fill;
  width: 100%;
  height: auto;
}
</style>
