<template>
  <div class="only-boarding">
    <div class="message-container">
      <div class="aligned-item">
        <div class="heading">Kun påstigning</div>
        <div>Ingen avstigning før {{ alightingStop.stopName }}</div>
      </div>
    </div>
    <!--    <div class="destination">-->
    <!--      {{ alightingStop.stopName }}  Uncomment this when we get forecasted arrival time  -->
    <!--    </div>-->
  </div>
</template>

<script>
export default {
  name: 'OnlyBoarding',
  props: {
    alightingStop: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
.only-boarding {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
}

.message-container {
  font-size: 4.16vw;
  flex: 1 1 auto;
  display: table;
}

.message-container .aligned-item {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.message-container .heading {
  font-size: 5.8vw;
  font-weight: 700;
  line-height: 14vmin;
}

.destination {
  flex: 0 1 auto;
  padding-left: 4.16vw;
  font-size: 5.8vw;
  line-height: 14vmin;
  margin: 2.5vmin 0;
  border-top: thin solid #cccccc;
  color: #cccccc;
}

@media (min-aspect-ratio: 30/9) {
  .message-container {
    font-size: 2.34vw;
  }

  .message-container .heading {
    font-size: 3.3vw;
  }

  .destination {
    font-size: 3.3vw;
  }
}
</style>
