import { logger } from '@/utils/logger'

export default {
  data() {
    return {
      notificationHubInfoMessages: []
    }
  },
  mounted() {
    this.registerNotHubSignalrHandlers()
    document.addEventListener('signalrconnected', this.registerNotHubSignalrHandlers)
  },
  beforeDestroy() {
    logger.info('Unsubscribing from SignalR Connection NotificationHubInfoMessage event', { testing: this.testing })
    this.$signalrConnection.off('NotificationHubInfoMessage')

    logger.info('Unsubscribing from SignalR Connection DisplayNotificationHubInfoMessagesInVehicle event', { testing: this.testing })
    this.$signalrConnection.off('DisplayNotificationHubInfoMessagesInVehicle')
  },
  methods: {
    registerNotHubSignalrHandlers() {
      logger.info('Subscribing to SignalR Connection NotificationHubInfoMessage event', { testing: this.testing })
      this.$signalrConnection.on('NotificationHubInfoMessage', ({ event, eventId }) => {
        if (event.operation === 'Publish' || event.operation === 'RePublish' || event.operation === 'Edit') {
          this._addNotificationHubInfoMessage(event)
        } else if (event.operation === 'Delete') {
          this._removeNotificationHubInfoMessage(event)
        } else {
          // NOTE: Other operations are not supported
        }
      })

      logger.info('Subscribing to SignalR Connection DisplayNotificationHubInfoMessagesInVehicle event', { testing: this.testing })
      this.$signalrConnection.on('DisplayNotificationHubInfoMessagesInVehicle', async ({ display }) => {
        const vehicleId = parseInt(localStorage.getItem('vehicleId'), 10)
        if (display) {
          if (vehicleId > 0) {
            await this.updateNotificationHubInfoMessages(vehicleId)
          }
        } else {
          this.notificationHubInfoMessages = []
        }
      })
    },
    async updateNotificationHubInfoMessages(vehicleId) {
      this.notificationHubInfoMessages = await this._fetchNotificationHubInfoMessages(vehicleId)
    },
    async _fetchNotificationHubInfoMessages(vehicleId) {
      try {
        const response = await this.$http.get(`/api/notification-hub-messages/vehicle/${Number(vehicleId)}`)
        return response.data ?? []
      } catch (e) {
        logger.error('Failed to fetch notification hub information messages for vehicle', e)
        return []
      }
    },
    _addNotificationHubInfoMessage(message) {
      const existingMessageIndex = this.notificationHubInfoMessages.findIndex(x => x.id === message.id)
      if (existingMessageIndex === -1) {
        this.notificationHubInfoMessages.push(message)
      } else {
        this.notificationHubInfoMessages.splice(existingMessageIndex, 1, message)
      }
    },
    _removeNotificationHubInfoMessage({ id }) {
      const existingMessageIndex = this.notificationHubInfoMessages.findIndex(x => x.id === id)
      if (existingMessageIndex !== -1) {
        this.notificationHubInfoMessages.splice(existingMessageIndex, 1)
      }
    }
  }
}
