<template>
  <div id="default-image-wrapper">
    <object data="/img/kolumbus-default.png" type="image/png" :title="alt" class="image-responsive">
      <h1 class="fallback-display">Takk for at du reiser kollektivt!</h1>
    </object>
    <clock class="route-clock"></clock>
  </div>
</template>

<script>
import Clock from './Clock.vue'
export default {
  name: 'DefaultImage',
  components: { Clock },
  props: {
    alt: {
      type: String,
      default: 'Default visning',
    },
  },
}
</script>

<style scoped>
#default-image-wrapper {
  background-color: #121212;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-responsive {
  max-width: 100%;
  max-height: 100%;
  height: auto;
}

.fallback-display {
  font-size: 6vw;
}

.route-clock {
  font-family: PostGrotesk, Helvetica, Arial, sans-serif;
  position: absolute;
  top: 4.63vmin;
  right: 4.16vw;
  font-size: 6.67vw;
  font-weight: bold;
  line-height: 15.8vmin;
  color: white;
  opacity: 0.5;
}
</style>
