<template>
  <div>
    <div class="content" :class="{ vessel: vesselDesign }">
      <div v-if="vesselDesign" class="vertical-line">
        <div class="dot"></div>
      </div>
      <stop-list-name class="name" ref="stopNameComponent" :name="nextStop.stopName" :vessel-design="vesselDesign"> </stop-list-name>
      <div v-show="showStopSignal" :key="stopKey" class="stopping-indicator">STOPPER</div>
      <div v-if="arrivalNoticeText" class="arrival-message-pad">{{ arrivalNoticeText }}</div>
    </div>
    <audio hidden ref="player">{{ playSound(nextStop) }}</audio>
    <div v-if="isPlayingSound" class="announcement-indicator"></div>
  </div>
</template>

<script>
import { secondsFromNow } from '@/utils/utilMisc'
import { logger } from '@/utils/logger'
import { NextStopArrivalNoticeStates } from '@/utils/constants'
import StopListName from '@/components/StopListName'

export default {
  name: 'NextStop',
  components: { StopListName },
  props: {
    nextStop: {
      type: Object,
      required: true,
    },
    showStopSignal: {
      type: Boolean,
      required: false,
    },
    isAmplifier: {
      type: Boolean,
      required: true,
    },
    activeDate: {
      type: String,
      required: true,
    },
    createdDate: {
      type: String,
      required: true,
    },
    arrivalNotice: {
      type: Number,
      required: false,
      default: 0,
    },
    vesselDesign: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      audio: null,
      isPlayingSound: false,
      seq: 0,
      stopKey: 0,
      intervalStopKey: null,
    }
  },
  watch: {
    showStopSignal() {
      this.$refs.stopNameComponent.publicRecalculateOnExternalWidthChange()
    },
    arrivalNoticeText() {
      this.$refs.stopNameComponent.publicRecalculateOnExternalWidthChange()
    },
  },
  computed: {
    arrivalNoticeText() {
      switch (this.arrivalNotice) {
        case NextStopArrivalNoticeStates.NEXT:
          return 'Neste'
        case NextStopArrivalNoticeStates.NOW:
          return 'Nå'
        default:
          return null
      }
    },
  },
  async mounted() {
    this.audio = this.$refs.player

    this.intervalStopKey = setInterval(() => {
      this.stopKey = Math.floor(Math.random() * 50)
    }, 250)
  },
  beforeDestroy() {
    clearInterval(this.intervalStopKey)
  },
  methods: {
    endListener() {
      this.isPlayingSound = false
      this.audio.removeEventListener('ended', this.endListener)
    },
    async playSound(nextStopSound) {
      if (!this.isAmplifier) return
      if (nextStopSound.sequenceOrder === this.seq) return

      this.seq = nextStopSound.sequenceOrder

      let timeStarted = this.$moment().format('HH:mm:ss.SSS')

      let started = this.$moment()
      // Don't play stopPlace sound on first stop
      if (nextStopSound.sequenceOrder === 1) return

      if (nextStopSound.stopPlaceId) {
        let response

        try {
          response = await this.$http.get(`api/sound/${nextStopSound.stopPlaceId}`, {
            responseType: 'blob',
            timeout: 15000, // 15 seconds
          })
        } catch (error) {
          const debugProperties = {
            stopId: nextStopSound.stopId,
            stopPlaceId: nextStopSound.stopPlaceId,
            sequenceOrder: nextStopSound.sequenceOrder,
            timeStarted,
            timeFinished: this.$moment().format('HH:mm:ss.SSS'),
          }
          let errorMessage = ''
          let errorDetails = null
          if (error.response) {
            // Request was sent to server, but status code is not 2xx
            switch (error.response.status) {
              case 404:
                errorMessage = 'Audio file missing.'
                break
              case 500:
                errorMessage = 'Server error when fetching audio file'
                break
              default:
                errorMessage = 'Getting audio file failed with statusCode ' + error.response.status
            }
          } else if (error.request) {
            // Request was sent, but we received no response from the server
            errorMessage = 'No response from server when fetching audio file'
            errorDetails = error.message

            if (error.message.indexOf('Network Error') > -1) {
              errorMessage = 'Lost internet before/while fetching audio file from server'
              errorDetails = null
            }
          } else {
            // Failed to send the request
            errorMessage = 'Failed to send request to server'
            errorDetails = error.message
          }

          logger.error(errorMessage, errorDetails, debugProperties)
          return
        }

        // valid timestamp is more than 30 sec do not play this file
        if (secondsFromNow(this.createdDate) > 30) {
          logger.error('Next stop sound request is more than 30 seconds old', null, {
            stopId: nextStopSound.stopId,
            stopPlaceId: nextStopSound.stopPlaceId,
            sequenceOrder: nextStopSound.sequenceOrder,
            timeStarted,
            timeFinished: this.$moment().format('HH:mm:ss.SSS'),
            timeFromServer: this.createdDate,
          })
          return
        }
        // Stop and reset audio, if there is a sound playing
        this.audio.pause()
        this.audio.currentTime = 0

        this.audio.addEventListener('ended', this.endListener)
        this.isPlayingSound = true

        try {
          this.audio.src = URL.createObjectURL(response.data)
          await this.audio.play()
        } catch (error) {
          logger.error('AudioPlayer failed to play stop sound', error.message, {
            stopId: nextStopSound.stopId,
            stopPlaceId: nextStopSound.stopPlaceId,
            sequenceOrder: nextStopSound.sequenceOrder,
            timeStarted,
            timeFinished: this.$moment().format('HH:mm:ss.SSS'),
          })
          // reset state
          this.endListener()
          return
        }

        try {
          const mac = sessionStorage.getItem('mac')
          const vehicleId = localStorage.getItem('vehicleId')
          const tripId = sessionStorage.getItem('tripId')

          await this.$http.post('/api/tracking/store-audio', {
            stopId: nextStopSound.stopId,
            stopPlaceId: nextStopSound.stopPlaceId,
            sequenceOrder: nextStopSound.sequenceOrder.toString(),
            mac,
            vehicleId,
            tripId,
            timeStarted: started,
            activeDate: this.activeDate,
          })
        } catch (error) {
          logger.error('Audio successfully played, but failed to persist status to server', error.message, {
            stopId: nextStopSound.stopId,
            stopPlaceId: nextStopSound.stopPlaceId,
            sequenceOrder: nextStopSound.sequenceOrder,
            timeStarted,
            timeFinished: this.$moment().format('HH:mm:ss.SSS'),
          })
        }

        logger.event('Next-stop-sound-played', {
          stopId: nextStopSound.stopId,
          stopPlaceId: nextStopSound.stopPlaceId,
          sequenceOrder: nextStopSound.sequenceOrder,
          timeStarted,
          timeFinished: this.$moment().format('HH:mm:ss.SSS'),
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.content {
  position: relative;
  font-size: 6.67vw;
  font-weight: 700;
  line-height: 15.8vmin;
  padding: 1.5vmin 0 1.5vmin 0;
  display: flex;
  justify-content: space-between;
  --bgr-color: 0, 0, 0;

  &.vessel {
    --bgr-color: 51, 63, 72; // same as #333F48;  Hovedfarger / Antrasitt
    background-color: rgb(var(--bgr-color));
  }

  .name {
    height: 15.8vmin;
  }

  .vertical-line {
    position: absolute;
    top: 0;
    left: 7.8vw;
    height: 100%;
    border-right: 20px solid $vessel-green-line;
    z-index: 200;

    .dot {
      box-sizing: border-box;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto 0;
      left: -40px;
      width: 100px;
      height: 100px;
      background: #ffffff;
      border: 20px solid $vessel-green-line;
      border-radius: 50%;
      z-index: 200;
    }
  }

  .announcement-indicator {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 0.5vmin;
    width: 0.3vw;
    background-color: #3c9cb4;
    z-index: 300;
  }

  .stopping-indicator {
    line-height: normal;
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    align-self: center;
    font-size: 4vw;
    color: #fafafa;
    background-color: #df2a2a;
    border-radius: 32px;
    text-transform: uppercase;
    padding: 0.5vmin 1vw;
    margin-right: 4.16vw;
  }

  .arrival-message-pad {
    margin-right: 4.16vw;
    position: relative;
  }
}

@media (min-aspect-ratio: 64/27) {
  .content {
    font-size: 5vw;
  } 
}

@media (min-aspect-ratio: 30/9) {
  .content {
    font-size: 3.75vw;
  }

  .stopping-indicator {
    margin-right: 4.16vw;
  }

  .stopping-indicator > span {
    font-size: 2.25vw;
    padding: 0.5vmin 0.6vw;
  }
}
</style>