import { logger } from '@/utils/logger'

// With heartbeats rate of 15s, logging every 8th will be equal to every 2 minutes
const heartbeat_before_logging = 8;

export default {
  data() {
    return {
      isOnline: null,
      refreshScreenWhenNoHeartbeatIn: 180,
      lastHeartbeat: null,
      lastHeartbeatServerTime: null,
      lastRefresh: new Date(),
      heartbeatCheckInterval: null,
      heartbeatCount: 0,
    }
  },
  created() {
    window.addEventListener('online', this.handleConnectionChanged)
    window.addEventListener('offline', this.handleConnectionChanged)
    document.addEventListener('signalrdisconnected', () => {
      sessionStorage.setItem('isOnlineChangedReason', 'signalr')
      this.isOnline = false
    })
    document.addEventListener('signalrconnected', () => {
      sessionStorage.setItem('isOnlineChangedReason', 'signalr')
      this.isOnline = true
    })

    document.addEventListener('signalrconnected', this.heartbeatHandler)
  },
  watch: {
    isOnline: {
      async handler(val) {
        sessionStorage.setItem('isOnline', val)
        sessionStorage.setItem('isOnlineChanged', this.$moment().toISOString())
        if (val) {
          let timestamp = localStorage.getItem('connectionLost')
          const now = this.$moment()
          if (timestamp) {
            // log to application insights if offline has been longer than 5 secs
            if (this.$moment(timestamp).isBefore(now.subtract(5, 'seconds'))) {
              let diff = now.diff(this.$moment(timestamp), 'seconds')
              logger.event('ScreenDevice was offline', {
                offlineAt: timestamp,
                onlineAgain: now.toISOString(),
                duration: this.$moment.duration(diff, 'seconds').humanize(),
                seconds: diff,
              })
            }
            localStorage.removeItem('connectionLost')
          }
        } else {
          localStorage.setItem('connectionLost', this.$moment().toISOString())
        }
        logger.trace(`isOnline Changed: ${sessionStorage.getItem('isOnlineChangedReason')}`)
      },
    },
  },
  methods: {
    async heartbeatHandler() {
      this.$signalrConnection.on('ServerHeartbeat', async heartbeatDateTime => {
        // Handle heartbeat message
        this.lastHeartbeat = new Date();
        this.lastHeartbeatServerTime = heartbeatDateTime;

        logger.trace(`Got heartbeat! Server time: ${this.lastHeartbeatServerTime}, Local time: ${this.lastHeartbeat}`)

        // log to server ever 2 minutes roughly
        this.heartbeatCount++
        if (this.heartbeatCount >= heartbeat_before_logging) {
          this.heartbeatCount = 0
          logger.traceEvent('Heartbeats received')
          try {
            await this.$http.put('/api/tracking/heartbeat-reply', {
              connectionId: this.$signalrConnection.connection.connectionId
            })
          } catch (e) {
            logger.error(`Sending heartbeat reply to server failed ${this.$signalrConnection.connection.connectionId}`, e)
          }
        }
      })

      if (this.heartbeatCheckInterval != null)
        clearTimeout(this.heartbeatCheckInterval);

      this.heartbeatCheckInterval = setInterval(() => {

        if (!this.isOnline) {
          // Do nothing when offline
          logger.traceEvent(`Screen is offline, heartbeat monitoring suspended`)
          return;
        }

        if (this.lastHeartbeat === null) {
          // No heartbeat received yet, fallback to refresh if no heartbeat is received within refresh limit
          let secondsSinceRefresh = (new Date().getTime() - this.lastRefresh.getTime()) / 1000
          logger.traceEvent(`No heartbeat yet, waited: ${secondsSinceRefresh}s`)

          if (secondsSinceRefresh > this.refreshScreenWhenNoHeartbeatIn)
            location.reload();

          return
        }

        // Check time since last heartbeat
        let lastHeartbeat = new Date(this.lastHeartbeat)
        let secondSinceLastHeartbeat = (new Date().getTime() - lastHeartbeat.getTime()) / 1000
        logger.trace(`Last heartbeat from server: ${secondSinceLastHeartbeat}s ago`)

        if (secondSinceLastHeartbeat > this.refreshScreenWhenNoHeartbeatIn)
          location.reload()

      }, 5000)
    },
    async handleConnectionChanged() {
      sessionStorage.setItem('isOnlineChangedReason', 'browser')
      if (navigator.onLine) {
        this.isOnline = await this.pingServer()
      } else {
        this.isOnline = false
      }
    },
    async pingServer() {
      try {
        await this.$http.head('/api/vehicle/ping')
        return true
      } catch (error) {
        sessionStorage.setItem('isOnlineChangedReason', 'server ping failed')
        logger.trace(`Head request to ping failed. Assuming we're offline. ${error}`)
        return false
      }
    },
  },
}
