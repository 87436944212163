<template>
  <div class="boot">
    <div title="Spill test lyd" style="position: absolute; width: 150px; height: 150px; cursor: pointer;" @click="playTestSound"></div>
    <div v-if="showMeta">
      <div class="content">
        <p>
          {{ error }}
        </p>

        <div v-if="deviceInfo">
          <table class="info-table">
            <tr>
              <th>MAC-adresse:</th>
              <td style="text-transform: uppercase;">{{ deviceInfo.id }}</td>
            </tr>
            <tr>
              <th>Kjøretøy ID:</th>
              <td>{{ deviceInfo.vehicleId }}</td>
            </tr>
            <tr>
              <th>Visningstype:</th>
              <td>{{ deviceInfo.displayType }}</td>
            </tr>
            <tr>
              <th>Tilkoblet forsterker:</th>
              <td>{{ deviceInfo.amplifier }}</td>
            </tr>
            <tr v-if="deviceInfo.placement && deviceInfo.placement > 0">
              <th>Plassering:</th>
              <td>{{ deviceInfo.placement }}</td>
            </tr>
            <!--            <tr>-->
            <!--              <th>Produsent:</th>-->
            <!--              <td>{{deviceInfo.manufacturer}}</td>-->
            <!--            </tr>-->
            <!--            <tr>-->
            <!--              <th>Notater:</th>-->
            <!--              <td>{{deviceInfo.note}}</td>-->
            <!--            </tr>-->
            <tr>
              <th>Metadata lastet:</th>
              <td>{{ retrievedDateTime }}</td>
            </tr>
            <tr>
              <th>Nettverk status:</th>
              <td>{{ isOnline ? 'Online' : 'Offline' }}</td>
            </tr>
            <tr v-if="gitHash">
              <th>Commit ID:</th>
              <td>{{ gitHash.substring(0, 7) }}</td>
            </tr>
            <tr>
              <th></th>
              <td>
                <router-link v-if="contentPath" :to="contentPath" title="Klikk for å gå til innholdsvisning nå">
                  Viser innhold om
                </router-link>
                <span> {{ secondsCount }}</span>s
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <default-image :alt="isOnline ? '' : 'Connection problems'"></default-image>
  </div>
</template>

<script>
import {delayWithPromise} from '@/utils/utilMisc'
import DefaultImage from '../components/DefaultImage'
import {getWithExpiry, setWithExpiry} from '@/utils/localStorageUtils'
import networkConnectivityListener from '../mixins/networkConnectivityListener'
import {logger} from '@/utils/logger'

export default {
  name: 'Boot',
  components: {DefaultImage},
  mixins: [networkConnectivityListener],
  props: {
    mac: {
      type: String,
      required: true,
    },
    testing: {
      type: Boolean,
      default: false,
    },
    reload: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      deviceInfo: null,
      vehicleId: null,
      isOnTrip: false,
      error: null,
      retrievedDateTime: null,
      totalTimeTriedSecs: 0,
      showMeta: true,
      threads: {
        secondsCounterInterval: null,
        redirectWaitTimeout: null,
        testSoundWaitTimeout: null,
      },
      secondsCount: 60,
      contentPath: null,
      gitHash: process.env.VUE_APP_GIT_HASH && process.env.VUE_APP_GIT_HASH.indexOf('#') < 0 ? process.env.VUE_APP_GIT_HASH : null,
    }
  },
  computed: {
    isBoat() {
      return this.vehicleId >= 250000000 && this.vehicleId <= 259999999
    },
    durationBeforeRedirectSecs() { return (this.isOnTrip ? 3 : 60) },
  },
  async created() {
    if (this.reload) {
      logger.trace('Reloading page', { reload: this.reload })
      let query = { ...this.$router.currentRoute.query }
      delete query.reload
      await this.$router.replace({ ...this.$router.currentRoute, query })
      window.location.reload()
    }

    this.vehicleId = localStorage.getItem('vehicleId')
    await this.$signalrConnection.start(this.mac, this.testing)

    /* Verify that we are running latest version */
    try {
      const result = await this.$http.get('/api/version')
      let gitHash = process.env.VUE_APP_GIT_HASH && process.env.VUE_APP_GIT_HASH.indexOf('#') < 0 ? process.env.VUE_APP_GIT_HASH : null
      // Track with App Insights
      if (gitHash) {
        logger.event('Booting', {
          mac: this.mac,
          testing: this.testing,
          gitHashClient: gitHash,
          gitHashServer: result.data.commitHash,
        })
        await this.$http.post('/api/tracking/booting', {
          mac: this.mac,
          gitHashClient: gitHash,
          gitHashServer: result.data.commitHash,
        })
      }
      logger.debug(`Verify latest version. git hash ${gitHash} client and from server: ${result.data.commitHash}`)
      if (gitHash && result.data.commitHash && gitHash !== result.data.commitHash) {
        const bootRefreshedVal = getWithExpiry('bootRefreshed')
        if (!bootRefreshedVal) {
          setWithExpiry('bootRefreshed', gitHash, 2 * 60 * 1000)
          logger.debug('refresh page!')
          if (process.env.NODE_ENV === 'development') window.location.reload()
          // https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle#manual_updates
          else navigator.serviceWorker.ready.then(registration => registration.update())
        } else {
          logger.debug(`Wont refresh page more. Tried ${bootRefreshedVal}`)
        }
      }
    } catch (error) {
      logger.error('Failed to get version from server. Wont refresh if required', error)
    }

    // don't wait for the sound to play
    // noinspection ES6MissingAwait
    this.playTestSound()
    await this.fetchScreenDeviceData()
    await this.afterConnected()
  },
  async beforeDestroy() {
    clearInterval(this.threads.secondsCounterInterval)
    clearTimeout(this.threads.redirectWaitTimeout)
    clearTimeout(this.threads.testSoundWaitTimeout)
  },
  methods: {
    /* Get screenDevice info so that we know what type it is and which vehicle it sits in */
    async fetchScreenDeviceData() {
      try {
        let response = await this.$http.get(`/api/boot/${this.mac}`)

        this.isOnline = true
        if (response.status === 200) {
          this.error = null
          this.retrievedDateTime = this.$moment().format('DD.MM.YYYY HH:mm:ss')
          if (response.data === null) {
            this.error = `MAC "${this.mac}" gjenkjent, men fått tomt svar fra server`
            await this.retryConnection()
          } else if (!response.data.displayType) {
            this.error = `MAC "${this.mac}" gjenkjent, men visningstype er ikke valgt`
            await this.retryConnection()
          } else if (!response.data.vehicleId) {
            this.error = `MAC "${this.mac}" gjenkjent, men kjøretøy er ikke valgt`
            await this.retryConnection()
          } else {
            this.deviceInfo = response.data
            // add vehicleId to session storage
            this.vehicleId = this.deviceInfo.vehicleId
            this.isOnTrip = this.deviceInfo.isOnTrip
            localStorage.setItem('vehicleId', this.deviceInfo.vehicleId)
            this.secondsCount = this.durationBeforeRedirectSecs
          }
        } else {
          if (response.status === 204) {
            this.error = `Ukjent MAC-adresse "${this.mac}"`
          } else if (response.status === 404) {
            this.error = 'Fant ikke Skjerm PC data på server. 404 Not Found'
          } else {
            this.error = `Ukjent svar fra server. ${response.status} ${response.statusText}`
          }
          await this.retryConnection()
        }
      } catch (error) {
        this.error = `Klarte ikke hente Skjerm PC konfig. ${error}`
        this.isOnline = false
        await this.retryConnection()
      }
      logger.debug(this.error)
    },
    async playTestSound() {
      let audio = new Audio('/test/testlydfil.mp3')
      // wait some secs before playing sound. If we test in browser it gives us time to click gui
      this.threads.testSoundWaitTimeout = setTimeout(async () => {
        try {
          await audio.play()
        } catch (error) {
          logger.error('Caught sound-play exception', error)
          this.soundPlayException = error
        }
      }, 7000)
    },
    async retryConnection() {
      if (this.totalTimeTriedSecs >= 60) {
        this.showMeta = false
        if (this.isBoat) {
          await this.redirectToContent()
        }
      }
      this.totalTimeTriedSecs += 10
      await delayWithPromise(10000)
      let status = await this.pingServer()
      if (status) {
        this.isOnline = true
        return this.fetchScreenDeviceData()
      }
      this.isOnline = false
      return await this.retryConnection()
    },
    async afterConnected() {
      // Track with AppInsights
      try {
        // setAuthenticatedUserContext(authenticatedUserId: string, accountId?: string, storeInCookie = false)
        // The method will only set the authenticatedUserId and accountId for all events in the current page view.
        // To set them for all events within the whole session, you should either call this method on every page view or set storeInCookie = true
        // https://github.com/microsoft/ApplicationInsights-JS/blob/master/API-reference.md#setauthenticatedusercontext
        this.$appInsights.setAuthenticatedUserContext(this.mac, this.deviceInfo.vehicleId, true)
      } catch (error) {
        logger.error('Failed to appInsights.setAuthenticatedUserContext during Boot', error)
      }
      this.countSeconds()
      await this.redirectToContent()
    },
    /* Simply count down seconds */
    countSeconds() {
      this.threads.secondsCounterInterval = setInterval(() => {
        this.secondsCount -= 1
        if (this.secondsCount >= this.durationBeforeRedirectSecs) {
          clearInterval(this.threads.secondsCounterInterval)
        }
      }, 1000)
    },
    /* Wait specified time before redirecting to appropriate view */
    async redirectToContent() {
      if (this.deviceInfo) {
        switch (this.deviceInfo.displayType) {
          case 'traseScreen':
            if (this.isBoat) {
              this.contentPath = {name: 'VesselRouteProgress', params: {mac: this.mac}}
            } else {
              this.contentPath = {name: 'VehicleRouteProgressMac', params: {mac: this.mac, vehicleId: this.deviceInfo.vehicleId}}
            }
            break;
          case 'traseWideScreen':
            this.contentPath = { name: 'VehicleWideScreenMac', params: { mac: this.mac, vehicleId: this.deviceInfo.vehicleId } }
            break;
          case 'infoScreen':
            this.contentPath = {name: 'MarketingMac', params: {mac: this.mac}}
            break;
          case 'arrivalSound':
            this.contentPath = {name: 'VehicleRouteProgressMac', params: {mac: this.mac}}
            break;
          default:
            console.error('unsupported display type')
            break;
        }
      } else if (this.isBoat) {
        this.contentPath = {name: 'VesselRouteProgress', params: {mac: this.mac}}
      } else {
        console.error('Unable to redirect, deviceInfo is null and we are not in boat context, trying to re-fetch screen device data')
        location.reload()
      }

      if (this.testing) {
        this.contentPath = {...this.contentPath, query: {testing: 'true'}}
      }

      this.threads.redirectWaitTimeout = setTimeout(async () => {
        await this.$router.push(this.contentPath)
      }, this.durationBeforeRedirectSecs * 1000)
    },
  },
}
</script>
<style scoped>
.boot {
  height: 100%;
  background-color: #333f48;
}

.content {
  color: #ddd;
  position: absolute;
  left: 20px;
  top: 20px;
  font-size: 1em;
}

.content a {
  color: #ddd !important;
}

.info-table th,
.info-table td {
  padding-bottom: 3px;
}

.info-table th {
  text-align: right;
  padding-right: 10px;
}

.info-table .spacer th {
  padding-bottom: 10px;
}
</style>
