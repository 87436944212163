<template>
  <div class="video-hd">
    <div class="video-container">
      <video class="video-responsive" autoplay loop muted>
        <source
            src="https://sanntidngkioskdlprod.blob.core.windows.net/videofiles/spinner-demo-60fps.mp4?sv=2021-04-10&st=2022-12-21T08%3A31%3A46Z&se=2040-01-01T08%3A31%3A00Z&sr=b&sp=r&sig=gPxKo3PHodmjykFApKV2rnk43D9BkvFVfb3el73eazQ%3D"
            type="video/mp4"/>
        Sorry, your browser doesn't support embedded videos.
      </video>
    </div>

    <div v-if="safetyVideoUrl" class="video-container">
      <video class="video-responsive" autoplay loop muted>
        <source :src="safetyVideoUrl"
                type="video/ogg"/>
        Sorry, your browser doesn't support embedded videos.
      </video>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoHd',
  data() {
    return {
      safetyVideoUrl: null,
      environment: process.env.VUE_APP_ENV,
    }
  },
  async mounted() {
    const kioskFaHost = `https://sanntidng-kiosk-fa-${this.environment === 'local'? 'dev' : this.environment}.azurewebsites.net`
    let response = await this.$http.get(`${kioskFaHost}/api/config/safetyVideoUrl`)
    this.safetyVideoUrl = response.data
  }
}
</script>

<style scoped>
.video-hd {
  height: 100%;
  padding-top: 20px;
}

.video-container {
  width: 100%; /* Can be any width we want */
  height: 48%;
  position: relative;
}

.video-responsive {
  width: 100%;
  height: 95%;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
