import { HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr'
import { logger } from '@/utils/logger'

const registeredEventHandlers = {}

export const signalrConnection = {
  install(Vue) {
    Vue.prototype.$signalrConnection = signalrConnection
  },
  connection: null,
  initializeSignalRConnection(connection, mac) {
    logger.debug(`initializing SignalR connection - mac: ${mac}`)
    connection.onreconnecting(() => {
      if (signalrConnection.connection.state === HubConnectionState.Reconnecting) {
        document.dispatchEvent(new CustomEvent('signalrdisconnected'))
      }
    })
    connection.onreconnected(async () => {
      if (signalrConnection.connection.state === HubConnectionState.Connected) {
        document.dispatchEvent(new CustomEvent('signalrconnected'))
        registerSignalrHandlers()
      }
    })
    connection.onclose(error => {
      document.dispatchEvent(new CustomEvent('signalrdisconnected'))
      if (error) {
        logger.error('SignalR connection closed with an exception', error)
      } else {
        logger.debug('SignalR connection closed')
      }
    })

    const registerSignalrHandlers = () => {
      connection.on('PlayTestSound', testSoundName => document.dispatchEvent(new CustomEvent('PlayTestSound', { detail: testSoundName })))
      connection.on('DisplayPlacement', dto => document.dispatchEvent(new CustomEvent('DisplayPlacement', { detail: dto })))
      connection.on('PushTest', PushedTimestamp => document.dispatchEvent(new CustomEvent('PushTest', {
        detail: {
          mac,
          PushedTimestamp,
          connectionId: connection.connectionId,
        },
      })))
    }
    registerSignalrHandlers()

    for (const configuration of signalrConnection.configurations) {
      configuration(connection, mac)
      logger.debug('applied extra configuration to SignalR connection during initialization')
    }
    return connection
  },
  buildSignalRConnection(mac, skipTracking) {
    logger.debug('building SignalR connection', { skipTracking })
    return signalrConnection.initializeSignalRConnection(
        new HubConnectionBuilder()
            .withUrl(
                '/api',
                mac
                    ? {
                      headers: {
                        'x-mac': mac,
                        'x-skip-tracking': skipTracking,
                      },
                    }
                    : undefined,
            )
            // https://docs.microsoft.com/en-us/aspnet/core/signalr/javascript-client?view=aspnetcore-5.0#reconnect-clients
            .withAutomaticReconnect({
              nextRetryDelayInMilliseconds: retryContext => {
                if (retryContext.elapsedMilliseconds > 10000) {
                  // If we've been reconnecting 10 seconds so far,
                  // wait between 10 seconds before the next reconnect attempt.
                  return 10000
                } else {
                  // wait 0, 1, 2, 3, 4, 5, 6, 7, 8, 9 seconds before re-attempt
                  return retryContext.previousRetryCount * 1000
                }
              },
            })
            .configureLogging(logger)
            .build(),
        mac,
    )
  },
  on(eventName, handler) {
    signalrConnection.off(eventName)
    signalrConnection.connection?.on(eventName, handler)
    registeredEventHandlers[ eventName ] = registeredEventHandlers[ eventName ] ? registeredEventHandlers[ eventName ] + 1 : 1
  },
  off(eventName) {
    signalrConnection.connection?.off(eventName)
    delete registeredEventHandlers[ eventName ]
  },
  async start(mac, skipTracking) {
    try {
      const _mac = sessionStorage.getItem('mac')
      if (_mac && _mac === mac && signalrConnection.connection && signalrConnection.connection.state === HubConnectionState.Connected) return

      if (signalrConnection.connection) {
        await signalrConnection.stop()
      }
      sessionStorage.setItem('mac', mac)

      signalrConnection.connection = signalrConnection.buildSignalRConnection(mac, skipTracking)

      logger.debug('starting SignalR connection', { skipTracking })
      await signalrConnection.connection.start()
      document.dispatchEvent(new CustomEvent('signalrconnected'))
    } catch (error) {
      logger.error(`An unexpected exception occurred while trying to start a SignalR connection.`, error, { skipTracking })
      setTimeout(() => signalrConnection.start(mac), 5000)
    }
  },
  async stop() {
    if (!signalrConnection.connection || signalrConnection.connection.state === HubConnectionState.Disconnected) return

    signalrConnection.connection.off('PlayTestSound')
    signalrConnection.connection.off('DisplayPlacement')
    signalrConnection.connection.off('PushTest')

    await signalrConnection.connection.stop()
  },
  async dispose() {
    if (!signalrConnection.connection) return

    logger.debug('disposing signalr')

    signalrConnection.connection.off('PlayTestSound')
    signalrConnection.connection.off('DisplayPlacement')
    signalrConnection.connection.off('PushTest')

    for (const registeredEventHandlersKey in registeredEventHandlers) {
      if (registeredEventHandlers[ registeredEventHandlersKey ] > 0) {
        logger.trace('removing all signalr handlers for event: ', registeredEventHandlersKey)
        signalrConnection.connection.off(registeredEventHandlersKey)
      }
    }

    await signalrConnection.connection.stop()
    signalrConnection.connection = null
  },
  configurations: [],
  configure(configuration) {
    signalrConnection.configurations.push(configuration)
    logger.trace('registered extra configuration to be applied during SignalR connection initialization')
  },
}

