<template>
    <div :class="['content', { regulation: ((stop == null) ? false : stop.isRegulationStop) }]">
        {{ getStopTime() }}
    </div>
</template>

<script>
export default {
    name: "StopTime",
    props: {
        stop: {
            type: Object,
            default: null,
        },
        forecast: {
            type: Object,
            default: null,
        },
    },
    methods: {
        getStopTime() {
            if (this.stop == null)
                return ''

            const isRegulationStop = this.stop.isRegulationStop

            const scheduledTime = (this.stop.sequenceOrder === 1 || isRegulationStop) ? this.stop.scheduledDepartureTime : this.stop.scheduledArrivalTime
            const predictedTime = (this.stop.sequenceOrder === 1 || isRegulationStop) ? this.forecast.predictedDepartureTime : this.forecast.predictedArrivalTime

            const timeChoice = ((predictedTime != null) ? predictedTime : scheduledTime)

            const arrivalTime = new Date(new Date(timeChoice).toLocaleString('en', { timeZone: 'Europe/Oslo' }))
            const currentTime = new Date(new Date().toLocaleString('en', { timeZone: 'Europe/Oslo' }))

            const diffSecRaw = ((arrivalTime - currentTime) / 1000)
            const diffSec = Math.floor(diffSecRaw)
            const diffMinutes = Math.floor(diffSecRaw / 60)
            const threshold = Number(this.$route.query.threshold || 15)

            if (diffSec <= threshold)
                return 'NÅ'
            else if (diffSec < 60)
                return '< 1 min'
            else if (diffMinutes <= 8)
                return `${diffMinutes} min`
            else
                return `${String(arrivalTime.getHours()).padStart(2, '0')}:${String(arrivalTime.getMinutes()).padStart(2, '0')}`
        },
    },
}
</script>

<style scoped lang="scss">
html {
    -moz-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
}

.content {
    position: absolute;
    overflow: visible;
    white-space: nowrap;
    font-size: 6.6667vw;
    right: 0px;
    top: 0px;
    line-height: 18.5vmin;
    padding-right: 4.1667vw;
    text-align: right;
    z-index: 250;
}

@media (min-aspect-ratio: 64/27) {
    .content {
        font-size: 5vw;
    }
}

@media (min-aspect-ratio: 30/9) {
    .content {
        font-size: 3vw;
    }
}
</style>