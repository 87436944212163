<template>
  <div class="text-typing">
    <span id="text-typing-line"></span>
    <span class="cursor" :class="{'blink': cursorBlink}">|</span>
  </div>
</template>
<script>
/* https://speckyboy.com/css-javascript-text-animation-snippets/ */
export default {
  name: "TextTypingAnimation",
  props: {
    text: {
      type: String,
      required: true,
    },
    // duration the full text line is displayed
    fullTextDuration: {
      type: Number,
      default: 2000,
    },
    // duration before next text is typed
    betweenTextDuration: {
      type: Number,
      default: 400
    },
    // speed that letters are typed
    typingSpeed: {
      type: Number,
      default: 100
    }
  },
  data() {
    return {
      el: null,
      loopNum: 0,
      texts: [],
      txt: '',
      isDeleting: false,
      cursorBlink: false
    }
  },
  mounted() {
    this.splitText()
    this.el = document.getElementById('text-typing-line')
    this.tick()
  },
  methods: {
    splitText() {
      const numberOfCharsThatFitOnLine = 37
      const words = this.text.split(' ')
      let txt = ''
      for (let i = 0; i < words.length; i++) {
        const part = txt.concat(' ', words[i])
        if (part.length > numberOfCharsThatFitOnLine) {
          this.texts.push(txt.trim())
          txt = words[i]
        } else
          txt = part
        if (i === words.length - 1 && txt.length > 0)
          this.texts.push(txt.trim())
      }
    },
    tick() {
      this.cursorBlink = false
      const i = this.loopNum % this.texts.length
      const fullTxt = this.texts[i]

      if (this.isDeleting) {
        this.txt = fullTxt.substring(0, this.txt.length - 1)
      } else {
        this.txt = fullTxt.substring(0, this.txt.length + 1)
      }

      this.el.innerText = this.txt

      const that = this
      let delta = this.typingSpeed  // - Math.random() * 100    // randomize to add more natural typing effect

      if (this.isDeleting) {
        delta /= 5 // deleting letters speed
      }

      if (!this.isDeleting && this.txt === fullTxt) {
        delta = this.fullTextDuration
        this.isDeleting = true
        this.cursorBlink = true
      } else if (this.isDeleting && this.txt === '') {
        this.isDeleting = false
        this.loopNum++
        if (i === this.texts.length - 1) {
          this.cursorBlink = true
          // delay more to indicate that we're starting from first line again
          delta = this.betweenTextDuration * 5
        } else
          delta = this.betweenTextDuration
      }

      setTimeout(function () {
        that.tick()
      }, delta)
    }
  }
}
</script>

<style scoped>
.cursor {
  margin-left: 3px;
  /*border-right: 0.06em solid #ccc;*/
  color: #555;
  font-family: Arial, sans-serif;
  font-weight: normal;
}

.cursor.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
</style>
