export const NextStopArrivalNoticeStates = {
  BLANK: 0,
  NEXT: 1,
  NOW: 2,
}

export const OnlineStates = {
  OK: 0,
  SHORT_LOSS: 1,
  LONG_LOSS: 2,
}
