<template>
  <div class="sound-test">
    <h1>Lydtest</h1>
    {{ apiText }}
  </div>
</template>

<script>
import { logger } from '@/utils/logger'

export default {
  name: 'SoundTest',
  components: {},
  data() {
    return {
      apiText: '',
      apiError: false,
    }
  },
  beforeDestroy() {
    clearInterval(this.id)
  },
  mounted() {
    this.playSoundLoop()
  },
  methods: {
    playSoundLoop() {
      this.id = setInterval(async () => {
        await this.playTestSound()
      }, 10000)
    },
    async playTestSound() {
      this.apiError = false
      this.apiText = 'Kaller api...'

      let response = await this.$http
        .get('api/sound/NSR:StopPlace:26269', {
          responseType: 'blob',
          timeout: 15000, // 15 seconds
        })
        .catch(error => {
          this.apiError = true
          if (!error) this.apiText = 'Klarte ikke å koble på API.'
          else {
            logger.debug(error.response)
            if (error.response.status === 404) {
              this.apiText = 'Fant ikke filen. Error 404'
              logger.debug('Audio test. No connection to api')
            }
          }
        })
      if (this.apiError) {
        this.apiText = 'Kunne ikke koble på API. Spiller lokal lydfil'
        let audio = new Audio('/test/NSR_StopPlace_26628.mp3')
        await audio.play()
      } else {
        let audio = new Audio()
        this.apiText = 'Koblet på API. Spiller av lyd'
        audio.src = URL.createObjectURL(response.data)
        await audio.play()
      }
    },
  },
}
</script>

<style scoped>
.sound-test {
  color: whitesmoke;
  text-align: center;
}
</style>
