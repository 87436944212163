<template>
  <svg id="ticker" ref="ticker">
    <text ref="ticker-text">
      <tspan dominant-baseline="text-before-edge">{{ text }}</tspan>
      <animate attributeType="XML" attributeName="x" repeatCount="indefinite" @repeatEvent="$emit('animation-ended')" ref="ticker-animation"/>
    </text>
  </svg>
</template>

<script>
export default {
  name: `SvgAnim`,
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  mounted() {
    const tickerEl = this.$refs.ticker
    const svgText = this.$refs['ticker-text']
    const svgAnimation = this.$refs['ticker-animation']
    const width = svgText.getBoundingClientRect().width

    const from = tickerEl.scrollWidth + 10
    const to = width * -1
    const duration = 10 + (this.text.length * 0.2)

    svgAnimation.setAttribute('from', from)
    svgAnimation.setAttribute('to', to)
    svgAnimation.setAttribute('dur', `${duration}s`)
  },
}
</script>

<style scoped>
#ticker {
  width: 100%;
  height: 11.6vmin;
}
</style>
