import moment from 'moment-timezone'

const secondsFromNow = timeStamp => {
  let duration = moment.duration(
    moment()
      .utc()
      .diff(timeStamp)
  )
  return duration.as('seconds')
}

const delayWithPromise = ms => {
  return new Promise(function(resolve) {
    let timeout = setTimeout(function() {
      clearTimeout(timeout)
      resolve()
    }, ms)
  })
}

const getWidthOfText = (text, fontFamily, fontSize) => {
  let canvas = document.createElement('canvas')
  let context = canvas.getContext('2d')
  context.font = fontSize + ' ' + fontFamily
  return context.measureText(text).width
}

const getWidthOfTextInElement = (elementClassName, text) => {
  let routeNameElem = document.querySelector(elementClassName)
  let styles = getComputedStyle(routeNameElem)
  let fontSize = styles.fontSize
  let fontFam = styles.fontFamily
  let canvas = document.createElement('canvas')
  let context = canvas.getContext('2d')
  context.font = fontSize + ' ' + fontFam
  return context.measureText(text).width
}

const getWidthOfTextInElementInVw = (elementClassName, text) => {
  let textWidth = getWidthOfTextInElement(elementClassName, text)
  let viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
  return (100 * textWidth) / viewportWidth
}

const makeQueryParamsString = paramsObj => {
  if (paramsObj === null || Object.keys(paramsObj).length === 0) return ''
  let searchParams = new URLSearchParams()
  Object.keys(paramsObj)
    .filter(key => paramsObj[key] !== null)
    .forEach(key => searchParams.set(key, paramsObj[key]))
  return '?' + searchParams.toString()
}

const toBoolean = str => {
  return !!str && (str.toUpperCase() === 'TRUE' || str === '1' || str.toUpperCase() === 'YES')
}

const debounce = (func, wait, immediate) => {
  let timeout
  return function() {
    const context = this, args = arguments
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export { delayWithPromise, secondsFromNow, makeQueryParamsString, getWidthOfText, getWidthOfTextInElement, getWidthOfTextInElementInVw, toBoolean, debounce }