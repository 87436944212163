<template>
    <svg viewBox="0 0 900 30" class="content">
        <text x="0" y="0" alignment-baseline="text-before-edge">Venter ved holdeplass</text>
    </svg>
</template>

<script>
export default {
    name: "IncomingRegulation",
}
</script>

<style scoped lang="scss">
.content {
    position: absolute;
    overflow: visible;
    font-size: 1vw;
    fill: currentColor;
    white-space: nowrap;
    left: 5vw;
    bottom: 0;
}

@media (min-aspect-ratio: 64/27) {
    .content {
        font-size: 0.8vw;
    }
}

@media (min-aspect-ratio: 30/9) {
    .content {
        top: 13vmin;
        left: 5vw;
    }
}
</style>