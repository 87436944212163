<template>
  <svg class="text-wrapper" ref="ticker">
    <text ref="ticker-text"
          :x="vesselDesign ? '15vw' : '4.16vw'"
          alignment-baseline="text-before-edge"
          :class="{'animation-ready': diff < 0}"
          :style="diff < 0 ? cssVars : {}">
      {{ name }}
    </text>
  </svg>
</template>

<script>

import {logger} from "../utils/logger";

export default {
  name: "StopListName",
  components: {},
  props: {
    name: {
      type: String,
      required: true,
    },
    vesselDesign: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      textWrapperId: null,
      textId: null,
      diff: 0,
      widthCalculationTimeout: null
    }
  },
  watch: {
    name: {
      immediate: true,
      handler() {
        this.calculateDiffBetweenContainerAndTextWidth()
      },
    },
  },
  computed: {
    cssVars() {
      return {
        "--move-distance": this.diff + 'px',
      };
    },
  },
  async beforeDestroy() {
    clearTimeout(this.widthCalculationTimeout)
  },
  methods: {
    calculateDiffBetweenContainerAndTextWidth() {
      this.widthCalculationTimeout = setTimeout(() => {
        try {
          let wrapperWidth = this.$refs['ticker'].getBoundingClientRect().width
          let textWidth = this.$refs['ticker-text'].getBoundingClientRect().width
          let diff = Math.ceil(wrapperWidth - textWidth)
          if (diff < -50) {
            this.diff = diff - 100 // move a further few px to avoid the last letter appearing under the gradient
          } else
            this.diff = 0

          logger.debug(`Name animation. Wrapper ${wrapperWidth}px text ${textWidth}px. Diff ${diff}px. ${this.name}`)
        } catch (e) {
          this.diff = 0
          logger.error(`Name animation calculation failed. ${this.name}`, e, {'text': this.name, 'diff': this.diff})
        } finally {
          clearTimeout(this.widthCalculationTimeout)
        }

      }, 500)
    },
    publicRecalculateOnExternalWidthChange() {
      logger.trace('Name animation. Width change')
      this.calculateDiffBetweenContainerAndTextWidth()
    }
  }
}
</script>

<style scoped lang="scss">
.text-wrapper {
  width: 100%;
  fill: currentColor;
  display: block;
  white-space: nowrap;
  height: 13.8vmin;

  .animation-ready {
    --move-distance: 0px;
    display: inline-block;
    animation: backAndForth 30s 5s infinite ease-in-out;
  }

  @keyframes backAndForth {
    0% {
      transform: translate(0);
    }
    /* move back */
    35% {
      transform: translateX(var(--move-distance));
    }

    /* Change these two ↑ ↓ percentages to control how long animation stays withdrawn */
    40% {
      transform: translateX(var(--move-distance));
    }

    /* move forth */
    60% {
      transform: translateX(0);
    }

    /* Between 60% and 100%, nothing changes, creating a delay between iterations */
    100% {
      transform: translateX(0);
    }
  }
}
</style>
