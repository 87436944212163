import axios from 'axios'

const getVehicleIdByMac = mac => {
  return axios.get(`/api/screendevice/${mac}`).then(response => {
    if (response.status === 200) {
      return Number(response.data.vehicleId)
    } else if (response.status === 204) throw new Error('Unknown MAC address')
    throw new Error('Unable to map MAC address to vehicle ID')
  })
}

const getMacByVehicleId = vehicleId => {
  return axios.get(`/api/screendevice/vehicle/${Number(vehicleId)}`).then(response => {
    if (response.status === 200) {
      return response.data.id
    } else {
      return null
    }
  })
}

const isVehicleIdValid = vehicleId => {
  return !(vehicleId == null || isNaN(vehicleId))
}

export { getVehicleIdByMac, getMacByVehicleId, isVehicleIdValid }