<template>
  <div id="app">
    <router-view />
    <push-notification></push-notification>
  </div>
</template>

<script>
import PushNotification from './PushNotification'
import update from '@/mixins/update'
import { logger } from '@/utils/logger'

export default {
  name: 'App',
  components: { PushNotification },
  mixins: [update],
  data() {
    return {
      lockResolver: null,
    }
  },
  created() {
    this.initializeAppInsights()
    this.initializeLockResolver()
    document.addEventListener('freeze', this.freezeEventHandler)
    document.addEventListener('resume', this.resumeEventHandler)
  },
  async beforeDestroy() {
    if (this.lockResolver) {
      this.lockResolver()
    }
    await this.$signalrConnection.dispose()
    document.removeEventListener('freeze', this.freezeEventHandler)
    document.removeEventListener('resume', this.resumeEventHandler)
  },
  methods: {
    initializeAppInsights() {
      try {
        this.$appInsights.context.application.build = process.env.VUE_APP_GIT_HASH
      } catch (error) {
        logger.error('Failed to set global appInsights properties.', error)
      }
    },
    initializeLockResolver() {
      try {
        // https://docs.microsoft.com/en-us/aspnet/core/signalr/javascript-client?view=aspnetcore-5.0#bsleep
        if (navigator && navigator.locks && navigator.locks.request) {
          const promise = new Promise(res => {
            this.lockResolver = res
          })
          navigator.locks.request('kolumbus.kiosk', { mode: 'shared' }, () => {
            return promise
          })
        }
      } catch (error) {
        logger.error('Failed to initialize lock resolver', error)
      }
    },
    freezeEventHandler(event) {
      const mac = sessionStorage.getItem('mac')
      logger.event('kiosk frozen', { mac, event })
    },
    resumeEventHandler(event) {
      const mac = sessionStorage.getItem('mac')
      logger.event('kiosk resumed', { mac, event, wasDiscarded: document.wasDiscarded })
    },
  },
}

</script>

<style lang="scss">
@import '@/styles/base.scss';
</style>
