<template>
  <div class="route-progress">
    <div v-if="playSafetyVideo">
      <video id="safety-video" autoplay muted @ended="playSafetyVideo = false">
        <source :src="safetyVideo" type="video/ogg" />
      </video>
      <clock class="route-clock"></clock>
    </div>
    <div v-else-if="haveStopsList && onlineStatus !== OnlineStates.LONG_LOSS" class="content-wrapper">
      <route-info :routeName="progressInfo.destinationText" :line="progressInfo.lineName"> </route-info>
      <stop-list
        :stopList="progressInfo.stops"
        :isAmplifier="false"
        :activeDate="progressInfo.activeDate"
        :createdDate="progressInfo.recordedTime"
        :showStopSignal="false"
        :next-stop-arrival-notice="nextStopArrivalNotice"
        vessel-design
      />
      <div v-if="onlineStatus === OnlineStates.SHORT_LOSS" class="overlay"></div>
      <notification v-show="onlineStatus === OnlineStates.SHORT_LOSS" icon-path="/img/signal-strength-none-yellow.png" msg="Venter på dekning"></notification>
      <div class="vertical-line-wrapper">
        <!-- Continues the vertical line when there are few stop items in list -->
        <div class="vertical-line"></div>
      </div>
    </div>
    <default-image v-else :alt="isOnline ? (haveStopsList ? 'Vessel default image' : 'No vessel itinerary') : 'Connection problems'"></default-image>
  </div>
</template>

<script>
import StopList from '@/components/StopList'
import { logger } from '@/utils/logger'
import { get, set } from 'idb-keyval'
import DefaultImage from '@/components/DefaultImage'
import networkConnectivityListener from '@/mixins/networkConnectivityListener'
import { Cron } from 'croner'
import RouteInfo from '@/components/RouteInfo'
import { debounce } from '@/utils/utilMisc'
import Notification from '@/components/Notification'
import Clock from '@/components/Clock'
import { NextStopArrivalNoticeStates } from '@/utils/constants'
import { OnlineStates } from '@/utils/constants'
import { getMacByVehicleId, isVehicleIdValid } from '@/shared/common'

export default {
  name: 'VesselRouteProgress',
  mixins: [networkConnectivityListener],
  components: { Notification, RouteInfo, DefaultImage, Clock, StopList },
  props: {
    mac: {
      type: String,
      required: true,
    },
    vehicleId: {
      type: Number,
      default: null,
    },    
    testing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      playSafetyVideo: false,
      playSafetyVideoOnIntervalInVessel: false,
      safetyVideo: null,
      cronPlaySafetyVideo: null,
      cronRestartApplication: null,
      progressInfo: {},
      nextStopArrivalNotice: NextStopArrivalNoticeStates.BLANK,
      featureFlagPoller: null,
      onlineStatus: OnlineStates.OK,
      onlineStatusTimeouts: {
        shortLossTimeout: null,
        longLostTimeout: null,
      },
      OnlineStates,
      updateFeatureFlag: debounce(
        async () => {
          this.playSafetyVideoOnIntervalInVessel = await this.fetchFeatureFlag('playSafetyVideoOnIntervalInVessel')
        },
        1000,
        true
      ),
      loadCurrentData: debounce(
        async () => {
          await this._loadCurrentData()
        },
        1000,
        true
      ),
    }
  },
  computed: {
    haveStopsList() {
      return this.progressInfo?.stops?.length > 0
    },
  },
  watch: {
    async isOnline(newValue) {
      if (newValue === false) {
        this.onlineStatusTimeouts.shortLossTimeout = setTimeout(() => {
          this.onlineStatus = OnlineStates.SHORT_LOSS
        }, 15000)

        this.onlineStatusTimeouts.longLostTimeout = setTimeout(() => {
          this.onlineStatus = OnlineStates.LONG_LOSS
        }, 90000)
      } else if (newValue === true) {
        clearTimeout(this.onlineStatusTimeouts.shortLossTimeout)
        clearTimeout(this.onlineStatusTimeouts.longLostTimeout)
        this.onlineStatus = OnlineStates.OK
        this.updateFeatureFlag()
        this.loadCurrentData()
      }
    },
  },
  async created() {
    if (this.mac == null && isVehicleIdValid(this.vehicleId)) {
      this.mac = await getMacByVehicleId(this.vehicleId)
      localStorage.setItem('vehicleId', this.vehicleId)
    }

    await this.$signalrConnection.start(this.mac, this.testing)

    this.registerSignalrHandlers()
    document.addEventListener('signalrconnected', this.registerSignalrHandlers)

    const [url, updated] = await this.getSafetyVideoUrl()
    if (updated) {
      await this.setSafetyVideo(await this.downloadSafetyVideo(url))
      this.safetyVideo = await this.getSafetyVideo()
    } else {
      this.safetyVideo = await this.getSafetyVideo()
      if (!this.safetyVideo) {
        await this.setSafetyVideo(await this.downloadSafetyVideo(url))
        this.safetyVideo = await this.getSafetyVideo()
      }
    }

    this.updateFeatureFlag()
    this.loadCurrentData()
  },
  mounted() {
    // every whole 5 minute, invoke playSafetyVideoFallback which plays the safety video if we are offline
    this.cronPlaySafetyVideo = new Cron('*/5 * * * *', () => this.playSafetyVideoFallback())
    // every night at 4, restart the application to boot url
    this.cronRestartApplication = new Cron('0 4 * * *', { timezone: 'Europe/Oslo' }, () => {
      const route = this.$router.resolve({ name: 'Boot', params: { mac: this.mac }, query: { reload: true } })
      logger.trace('Navigating to boot', route)
      window.location.assign(route.href)
    })
  },
  beforeDestroy() {
    this.cronPlaySafetyVideo.stop()
    this.cronRestartApplication.stop()

    this.$signalrConnection.off('TrippTrappItineraryUpdate')
    logger.info('Unsubscribing from SignalR Connection TrippTrappItineraryUpdate', { testing: this.testing })

    this.$signalrConnection.off('VesselDepartureEvent')
    logger.info('Unsubscribing from SignalR Connection VesselDepartureEvent', { testing: this.testing })

    this.$signalrConnection.off('VesselArrivalEvent')
    logger.info('Unsubscribing from SignalR Connection VesselArrivalEvent', { testing: this.testing })

    this.$signalrConnection.off('DisplayTraseScreenInVessel')
    logger.info('Unsubscribing from SignalR Connection DisplayTraseScreenInVessel', { testing: this.testing })

    this.$signalrConnection.off('PlaySafetyVideoOnIntervalInVessel')
    logger.info('Unsubscribing from SignalR Connection PlaySafetyVideoOnIntervalInVessel', { testing: this.testing })

    clearInterval(this.featureFlagPoller)
    clearTimeout(this.onlineStatusTimeouts.shortLossTimeout)
    clearTimeout(this.onlineStatusTimeouts.longLostTimeout)
  },
  methods: {
    arrivingAtNextStop(event) {
      return this.haveStopsList && this.progressInfo.stops[0].stopId === event.stopPlaceId
    },
    registerSignalrHandlers() {
      // Listen to SignalR events to updated the list of stops
      logger.info('Subscribing to SignalR Connection TrippTrappItineraryUpdate', { testing: this.testing })
      this.$signalrConnection.on('TrippTrappItineraryUpdate', async ({ event, eventId }) => {
        logger.event('TrippTrappItineraryUpdate event received in client', { connectionId: this.$signalrConnection.connection.connectionId, testing: this.testing, event, eventId })
        this.handleItineraryUpdate(event)
      })

      logger.info('Subscribing to SignalR Connection VesselDepartureEvent', { testing: this.testing })
      this.$signalrConnection.on('VesselDepartureEvent', async ({ event, eventId }) => {
        logger.event('VesselDepartureEvent received in client', { connectionId: this.$signalrConnection.connection.connectionId, testing: this.testing, event, eventId })
        if (!this.playSafetyVideoOnIntervalInVessel) this.playSafetyVideo = true

        this.nextStopArrivalNotice = NextStopArrivalNoticeStates.NEXT
      })

      logger.info('Subscribing to SignalR Connection VesselArrivalEvent', { testing: this.testing })
      this.$signalrConnection.on('VesselArrivalEvent', async ({ event, eventId }) => {
        logger.event('VesselArrivalEvent received in client', { connectionId: this.$signalrConnection.connection.connectionId, testing: this.testing, event, eventId })
        if (!this.playSafetyVideoOnIntervalInVessel) this.playSafetyVideo = false

        if (this.arrivingAtNextStop(event)) this.nextStopArrivalNotice = NextStopArrivalNoticeStates.NOW
        // When we arrive at last stop, show default message, otherwise we display this trip until we get departure event on next trip.
        // We do this check here, as well as on ItineraryUpdate because boats sometimes log off trip quite a while before they arrive to last stop, and when
        // that happens we only get arrival/departure events, not itineraryUpdate
        if (this.progressInfo.stops?.length < 2) this.progressInfo = {}
      })

      logger.info('Subscribing to SignalR Connection DisplayTraseScreenInVessel', { testing: this.testing })
      this.$signalrConnection.on('DisplayTraseScreenInVessel', async ({ display }) => {
        logger.trace('DisplayTraseScreenInVessel', { display })
        if (display) {
          this.loadCurrentData()
        } else {
          this.progressInfo = {}
        }
      })

      logger.info('Subscribing to SignalR Connection PlaySafetyVideoOnIntervalInVessel', { testing: this.testing })
      this.$signalrConnection.on('PlaySafetyVideoOnIntervalInVessel', async ({ playSafetyVideoOnIntervalInVessel }) => {
        logger.trace('PlaySafetyVideoOnIntervalInVessel', { playSafetyVideoOnIntervalInVessel })
        this.playSafetyVideoOnIntervalInVessel = playSafetyVideoOnIntervalInVessel
      })
    },
    async getSafetyVideoUrl() {
      const localUrl = localStorage.getItem('safetyVideoUrl')
      try {
        const response = await this.$http.get('/api/config/safetyVideoUrl')
        if (response.status !== 200 || response.data === localUrl) {
          return [localUrl, false]
        }
        localStorage.setItem('safetyVideoUrl', response.data)
        return [response.data, true]
      } catch (error) {
        logger.error('Unable to fetch safety video blob url', error.message)
        return [localUrl, false]
      }
    },
    async downloadSafetyVideo(url) {
      try {
        const response = await this.$http.get(url, { responseType: 'blob' })
        return response?.data
      } catch (error) {
        logger.error('Unable to download safety video blob', error.message)
        return null
      }
    },
    async getSafetyVideo() {
      try {
        const blob = await get('video')
        return blob ? URL.createObjectURL(blob) : null
      } catch (error) {
        logger.error('Unable to fetch safety video blob from indexed db', error.message)
        return null
      }
    },
    async setSafetyVideo(blob) {
      try {
        await set('video', blob)
      } catch (error) {
        logger.error('Unable to store safety video blob in indexed db', error.message)
      }
    },
    playSafetyVideoFallback() {
      if (this.isOnline === false || this.playSafetyVideoOnIntervalInVessel) {
        logger.trace(`isOnline: ${this.isOnline}, playing safety video: ${new Date().getMinutes()}.`)
        this.playSafetyVideo = true
      }
    },
    mapTrippTrappItineraryUpdate(event) {
      return {
        ...event,
        stops: event.stops.map(stop => ({
          stopId: stop.stopPlaceId,
          stopName: stop.placeName,
          sequenceId: stop.sequenceId,
        })),
      }
    },
    nextStopIsFirstStop(progressInfo) {
      return progressInfo.stops.length > 0 && progressInfo.stops[0].sequenceId === 0
    },
    nextStopIsEqual(progressInfoA, progressInfoB) {
      return (progressInfoA?.stops ?? [{}])[0].stopId === (progressInfoB?.stops ?? [{}])[0].stopId
    },
    handleItineraryUpdate(event) {
      this.onlineStatus = OnlineStates.OK
      const incomingEventIsNewer = event && event.recordedTime && (!this.progressInfo.recordedTime || this.$moment(event.recordedTime).isAfter(this.progressInfo.recordedTime))
      if (incomingEventIsNewer) {
        if (event.stops.length > 0) {
          const progressInfo = this.mapTrippTrappItineraryUpdate(event)
          if (
            this.nextStopIsFirstStop(progressInfo) ||
            // If the second part of the expression evaluates to true it means we got a TrippTrappItineraryUpdate event after an ArrivalEvent with the same next stop.
            // The ArrivalEvent sets NextStopArrivalNoticeStates.NOW, and we dont want it to be set to NextStopArrivalNoticeStates.NEXT unless next stop has changed.
            (this.nextStopArrivalNotice === NextStopArrivalNoticeStates.NOW && this.nextStopIsEqual(this.progressInfo, progressInfo))
          ) {
            this.nextStopArrivalNotice = NextStopArrivalNoticeStates.NOW
          } else {
            this.nextStopArrivalNotice = NextStopArrivalNoticeStates.NEXT
          }
          this.progressInfo = progressInfo
        } else {
          this.progressInfo = {}
          this.nextStopArrivalNotice = NextStopArrivalNoticeStates.BLANK
        }
      }
    },
    async _loadCurrentData() {
      try {
        const response = await this.$http.get(`/api/stop/vessel/current?mac=${this.mac}&vesselId=${this.vehicleId || -1}`)
        if (response.data) {
          this.handleItineraryUpdate(response.data)
        }
      } catch (error) {
        logger.error('Failed to eagerly load stop list', error.message)
      }
    },
    async fetchFeatureFlag(featureFlag) {
      try {
        const response = await this.$http.get(`/api/feature-flag/${featureFlag}`)
        return response.data ?? false
      } catch (error) {
        logger.error(`Failed to fetch feature flag '${featureFlag}'`, error.message)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.route-progress {
  font-family: PostGrotesk, Helvetica, Arial, sans-serif;
  height: 100%;
  display: initial;

  .content-wrapper {
    display: flex;
    flex-flow: column;
    height: 100%;
  }

  .vertical-line-wrapper {
    position: relative;
    height: 100%;
  }

  .vertical-line {
    position: absolute;
    top: 0;
    left: 7.8vw;
    height: 100%;
    border-right: 20px solid $vessel-green-line;
  }
  .route-clock {
    font-family: PostGrotesk, Helvetica, Arial, sans-serif;
    position: absolute;
    bottom: -1.16vmin;
    left: 2.08vw;
    font-size: 3.335vw;
    font-weight: bold;
    line-height: 15.8vmin;
    color: white;
    opacity: 0.5;
    z-index: 3;
  }

  #safety-video {
    background: black;
    position: absolute;
    z-index: 2;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  .overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    z-index: 400;
  }
}
</style>
