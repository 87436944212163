<template>
  <div class="sound">
    <div>
      <button title="Spill test lyd" @click="playTestSound()">Spill tale lyd</button>
      <span v-if="testSoundPlaying">♫</span>
      <br><br>
      <button v-if="!whiteNoisePlaying" title="Spill whitenoise lyd" @click="playTestSound(true)">Spill white noise</button>
      <span v-else>
        <button title="Stop whitenoise lyd" @click="stopSound">Stopp white noise</button> ♫
      </span>
      Denne lyden er 61 dBA, 1 meter fra høyttaler
    </div>
    <div v-if="errorMsg" class="error-message">
      <h4 style="margin-top: 0;">Feilmelding</h4>
      {{ errorMsg }}
    </div>
    <div style="font-size: 40vh; color: darkolivegreen;">Lyd</div>
  </div>
</template>

<script>
import {logger} from "@/utils/logger";

export default {
  name: "Sound",
  data() {
    return {
      startedAudios: [],
      testSoundPlaying: false,
      whiteNoisePlaying: false,
      errorMsg: null,
      isSlideStart: true
    }
  },
  beforeDestroy() {
    this.stopSound()
  },
  async mounted() {
    await this.playTestSound()
  },
  methods: {
    async playTestSound(whiteNoiseSound = false) {
      let fileName = whiteNoiseSound ? 'test-sound-19-lufs-61-dba-slow-1-meter-from-source' : 'testlydfil'
      let audio = new Audio(`/test/${fileName}.mp3`)
      try {
        this.startedAudios.push(audio)
        if (whiteNoiseSound)
          this.whiteNoisePlaying = true
        else
          this.testSoundPlaying = true
        audio.addEventListener('ended', this.audioEndListener)
        await audio.play()
        this.errorMsg = null
      } catch (error) {
        logger.error('Caught sound-play exception', error)
        this.errorMsg = error.message
      }
    },
    stopSound() {
      this.startedAudios.forEach(audio => {
        audio.pause();
        audio.currentTime = 0;
      })
      this.whiteNoisePlaying = false
    },
    audioEndListener() {
      if (this.testSoundPlaying)
        this.testSoundPlaying = false
      if (this.whiteNoisePlaying)
        this.whiteNoisePlaying = false
      if (this.isSlideStart)
        this.playTestSound(true)
      this.isSlideStart = false
    },
  }
}
</script>

<style scoped>
.sound {
  padding-left: 2vw;
  padding-top: 2vw;
}

.error-message {
  margin-top: 4vh;
  border: 1px solid dimgray;
  color: darkorange;
  padding: .5rem;
  max-width: 70vw;
}
</style>
