<template>
  <div class="animations">
    <text-typing-animation style="font-size: 3em;" :text="textsToRotate" :full-text-duration="2000"></text-typing-animation>
    <svg-anim style="fill: whitesmoke; font-size: 3em;" :text="textsToRotate" :duration="50"></svg-anim>
    <boxes-with-text></boxes-with-text>
  </div>
</template>

<script>

import TextTypingAnimation from "@/views/demo-screen/slides/animations/TextTypingAnimation";
import SvgAnim from "@/views/demo-screen/slides/animations/SvgAnim";
import BoxesWithText from "@/views/demo-screen/slides/animations/BoxesWithText";

export default {
  name: "Animations",
  components: {BoxesWithText, SvgAnim, TextTypingAnimation},
  data() {
    return {
      textsToRotate: 'Holdeplassen Stavanger konserthus er midlertidig flyttet til plattform B7/B8 retning vest grunnet veiarbeid som avsluttes 30 oktober 2023.',
    }
  },
}
</script>

<style scoped lang="scss">
.animations {
  padding: 2rem;
}

</style>
