<template>
  <div :class="{ big: big, small: !big, wrapper: true }">
    <div class="showPlacement">
      <slot name="default">
        {{ info }}
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DisplayInfo',
  props: {
    info: {
      type: String,
      default: '',
    },
    big: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped lang="scss">
.small {
  &.wrapper {
    position: absolute;
    z-index: 100;
    bottom: 1.3rem;
    left: 1.3rem;
    color: #ddd;
    font-size: 1.5rem;
  }
}
.big {
  &.wrapper {
    background-color: #121212;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.showPlacement {
    color: white;
    top: 30%;
    padding-left: 2rem;
    padding-right: 2rem;
    font-size: 48px;
  }
}
</style>
