<template>
  <div class="wide-screen">
    <iframe :src="routeProgressUrl"></iframe>
    <iframe :src="marketingUrl"></iframe>
    <!-- This div is not visible on the physical screen because only the top half of what we see here is actually visible.
     The purpose of this content is to be able to determine whether we have a black screen or the screen rotation is incorrect (then we see bottom half) -->
    <div class="hidden-on-widescreen">Kolumbus</div>
  </div>
</template>

<script>
export default {
  name: 'VehicleWideScreen',
  props: {
    mac: {
      type: String,
      default: null,
    },
    testing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      routeProgressUrl: null,
      marketingUrl: null
    }
  },
  async created() {

    let routeProgressRoute = this.$router.resolve({
      name: 'VehicleRouteProgressMac',
      params: { mac: this.mac, testing: this.testing },
    });
    this.routeProgressUrl = routeProgressRoute.href;

    let marketingRoute = this.$router.resolve({
      name: 'MarketingMac',
      params: { mac: this.mac, testing: this.testing },
    });
    this.marketingUrl = marketingRoute.href;

  },
}
</script>

<style scoped lang="scss">
  iframe, img {
    height: 49.444vh; // 534px of 1080px
    width: 50vw;
    border: 0;
    float: left;
  }
  .hidden-on-widescreen {
    position: absolute;
    bottom: 100px;
    left: 50%;
    font-size: larger;
    transform: translateX(-50%) rotate(180deg);
  }
</style>
