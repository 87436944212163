/* eslint-disable no-console */

import { register } from 'register-service-worker'
import { logger } from '@/utils/logger'

if (process.env.NODE_ENV !== 'development') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      logger.debug('App is being served from cache by a service worker.\n' + 'For more details, visit https://goo.gl/AFskqB')
    },
    registered(registration) {
      logger.debug('Service worker has been registered')
      // vuetifyjs.com do this. Routinely check for app updates by testing for a new service worker.
      setInterval(() => {
        registration.update(registration)
      }, 1000 * 60 * 10) // 10 minute checks
    },
    cached() {
      logger.debug('Content has been cached for offline use')
    },
    updatefound() {
      logger.debug('New content is downloading')
    },
    updated(registration) {
      logger.debug('New content is available; please refresh')

      // Vuetifyjs.com do this, and https://dev.to/drbragg/handling-service-worker-updates-in-your-vue-pwa-1pip
      const event = new CustomEvent('swupdatefound', { detail: registration })
      document.dispatchEvent(event)
    },
    offline() {
      logger.debug('No internet connection found. App is running in offline mode')
    },
    error(error) {
      logger.error('Error during service worker registration', error)
    },
  })
}
