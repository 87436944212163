<template :key="componentKey">
  <div class="network">
    <h2>Nettverk test og minne</h2>
    <div>
      <h4>Chrome's innebygde <em>connection</em> målinger
        <button style="position: relative; left: 20px;" @click="forceRerender">Refresh</button>
      </h4>
      <!-- ComponentKey must be featured in template for refresh to work -->
      <span style="display: none;">{{ componentKey }}</span>
      <table class="connection-table">
        <tr>
          <td>Effektiv båndbredde</td>
          <td>{{ conn.downlink }}<span v-if="conn.downlink === 10">+</span> Mbps</td> <!-- This is never higher than 10 -->
        </tr>
        <tr>
          <td>Estimert nettverk type</td>
          <td style="color: grey;">
            <span v-for="type in ['slow-2g', '2g', '3g', '4g']" :key="type" :class="{'highlighted-type': type === conn.effectiveType}">
              {{ type.replace('g', 'G') }}
            </span>
          </td>
        </tr>
        <tr>
          <td>Round-trip time</td>
          <td>{{ conn.rtt }} ms</td>
        </tr>
      </table>
      <strong v-if="conn.effectiveType && conn.effectiveType.indexOf('2g') >= 0" style="color: darkorange;">
        Hastighet tilfredstiller ikke krav
      </strong>
    </div>
    <div v-if="imageLoadSpeed">
      <h4>Nettverk hastighet ved lasting av bilde
        <button style="position: relative; left: 20px;" @click="calculateNetworkSpeedByImageLoad">Refresh</button>
      </h4>
      {{ imageLoadSpeed.kbps }} kbps <br>
      {{ imageLoadSpeed.mbps }} Mbps <br>
      {{ imageLoadDuration.toFixed(1) }} ms tok lastingen
      <span v-show="imageLoadDuration > 1000" class="warning-text">Dette er mer enn 1 sekund</span>
    </div>
    <div>
      <h4>Minne</h4>
      <table v-if="mem" class="connection-table">
        <tr>
          <td>Aktivt brukt minne</td>
          <td>
            {{ Math.round(mem.usedJSHeapSize / 1024 / 1024) }} MB
          </td>
        </tr>
        <tr>
          <td>Totalt minne tilgjengelig</td>
          <td>
            {{ Math.round(mem.jsHeapSizeLimit / 1024 / 1024) }} MB
            <strong v-if="Math.round(mem.jsHeapSizeLimit / 1024 / 1024) < 200" class="warning-text">
              Totalt minne tilfredstiller ikke krav
            </strong>
          </td>
        </tr>
      </table>
      <div id="imageBlobContainer">
        <h4>Bilder lagret i minne som blobs</h4>
      </div>
      <div id="imageDbContainer">
        <h4>Bilder lagret i IndexDb</h4>
      </div>
    </div>
  </div>
</template>

<script>

import {delayWithPromise} from "@/utils/utilMisc";

export default {
  name: "Network",
  data() {
    return {
      conn: {},
      // Bit of a hack to refresh the component. Only then could I manage to refresh the navigator.connection
      componentKey: 1,
      imageLoadSpeed: null,
      imageLoadDuration: null,
      mem: null,
      db: null
    }
  },
  async mounted() {
    await this.getNavigatorConnection()
    this.getPerformanceMemory()
    this.calculateNetworkSpeedByImageLoad()
    await delayWithPromise(1000)
    await this.loadImagesAsObjectUrls()
    await delayWithPromise(1000)
    await this.indexedDbInitialize()
  },
  methods: {
    async getNavigatorConnection() {
      this.conn = navigator.connection
    },
    forceRerender() {
      this.componentKey += 1
    },
    getPerformanceMemory() {
      // https://developer.mozilla.org/en-US/docs/Web/API/Performance/memory
      if (window.performance) {
        console.log(`Used memory at mount ${(window.performance.memory.usedJSHeapSize / 1024 / 1024).toFixed(2)}`)
        this.mem = window.performance.memory
      }

    },
    // https://www.geeksforgeeks.org/how-to-detect-network-speed-using-javascript/
    calculateNetworkSpeedByImageLoad() {
      const imageAddr = '/test/kolumbus-nuart-01.jpg'
      const downloadSize = 631256 // bytes
      let time_start
      const downloadImgSrc = new Image()

      downloadImgSrc.onload = () => {
        const end_time = performance.now()
        this.imageLoadDuration = (end_time - time_start)
        const loadDurationSec = this.imageLoadDuration / 1000
        const loadedBits = downloadSize * 8

        /* Converts a number into string using toFixed(2) rounding to 2 */
        const bps = (loadedBits / loadDurationSec).toFixed(2)
        const speedInKbps = (bps / 1024).toFixed(2)
        const speedInMbps = (speedInKbps / 1024).toFixed(2)

        this.imageLoadSpeed = {
          kbps: speedInKbps,
          mbps: speedInMbps
        }
      }

      time_start = performance.now()
      downloadImgSrc.src = imageAddr + '?' + performance.now()
    },
    async loadImagesAsObjectUrls() {
      for (let i = 0; i < 10; i++) {
        let response = await this.$http.get('/test/kolumbus-nuart-01.jpg', {responseType: 'blob'})
        this.addImageElementToDom(response.data, 'imageBlobContainer')
      }
    },
    async indexedDbInitialize() {
      const dbVersion = 1
      let dbReady = false

      let request = indexedDB.open('demoPics', dbVersion)

      request.onerror = (e) => {
        console.error('Unable to open database. ')
      }

      request.onsuccess = async (e) => {
        this.db = e.target.result
        console.log('db opened')

        // clear data
        let transaction = this.db.transaction(['demoPics'], 'readwrite')
        let objectStore = transaction.objectStore("demoPics")
        objectStore.clear()

        for (let i = 0; i < 10; i++) {
          await this.indexedDbLoadAndInsertFiles()
        }
        await this.indexedDbGetAndDisplayAllFiles()
      }

      request.onupgradeneeded = (e) => {
        let database = e.target.result
        database.createObjectStore('demoPics', {keyPath: 'id', autoIncrement: true})
        dbReady = true
      }
    },
    async indexedDbLoadAndInsertFiles() {
      let response = await this.$http.get('/test/kolumbus-nuart-01.jpg', {responseType: 'blob'})
      let ob = {
        created: new Date(),
        data: response.data
      }

      let trans = this.db.transaction(['demoPics'], 'readwrite')
      let addReq = trans.objectStore('demoPics').add(ob)

      addReq.onerror = (e) => {
        console.log(`error storing data ${e.message}`)
      }

      trans.oncomplete = (e) => {
        console.log(`data stored ${e}`)
      }
    },
    async indexedDbGetAndDisplayAllFiles() {
      let trans = this.db.transaction(['demoPics'], 'readonly')

      let req = trans.objectStore('demoPics').getAll()
      req.onsuccess = (e) => {
        let records = e.target.result
        records.forEach(record => {
          this.addImageElementToDom(record.data, 'imageDbContainer')
        })
      }
    },
    addImageElementToDom(blob, divContainerId) {
      const elem = document.createElement("img")
      elem.src = URL.createObjectURL(blob)
      elem.setAttribute('height', 50)
      elem.setAttribute('width', 50)
      document.getElementById(divContainerId).appendChild(elem)
    }
  }
}
</script>

<style scoped lang="scss">
.network {
  color: whitesmoke;
  padding: 2rem;

  h4 {
    margin-bottom: .5em;
  }

  .connection-table {
    td {
      padding: 2px 4px 2px 0;
    }
  }

  .highlighted-type {
    font-size: larger;
    color: white;
  }

  .warning-text {
    color: darkorange;
  }

  #imageContainer {
    padding: 10px 3px;

    img {
      padding: 5px;
    }
  }
}
</style>
