<template>
  <div class="demo-screen">
    <component v-bind:is="slides[slideIndex].component"></component>
    <nav class="nav">
      <div>
        Slide {{ slideIndex + 1 }}/{{ slides.length }} {{ slides[slideIndex].name }}
      </div>
      <div style="padding: 10px 0 20px 0;">
        <button title="Skru av eller på auto-slide. Bruker velger side selv eller det skifter automatisk" @click="toggleAutoSlide">
          {{ (autoSlide ? 'Skru av ' : 'Skru på ') + 'auto-slide' }}
        </button>
      </div>
      <div style="padding-bottom: 20px;">
        <label>
          Slide hastighet <br>
          <select :value="speed" @change="changeSlideTransitionSpeed">
            <option value="15">15 sek</option>
            <option value="30">30 sek</option>
            <option value="120">2 min</option>
            <option value="600">10 min</option>
          </select>
        </label>
      </div>
      <button style="margin-right: 20px;" title="Vis forrige side" @click="decrementIndex">Forrige</button>
      <button title="Vis neste side" @click="incrementIndex">Neste</button>
    </nav>
  </div>

</template>

<script>
import VideoHd from "@/views/demo-screen/slides/VideoHd";
import Sound from "@/views/demo-screen/slides/Sound";
import Animations from "@/views/demo-screen/slides/Animations";
import RouteProgressDummy from "@/views/demo-screen/slides/RouteProgressDummy";
import InfoScreenDummy from "@/views/demo-screen/slides/InfoScreenDummy";
import Network from "@/views/demo-screen/slides/Network";

export default {
  name: "Demo-screen",
  components: {Network, InfoScreenDummy, RouteProgressDummy, Animations, Sound, VideoHd},
  props: {
    slideIndex: {
      type: Number,
      default: 0,
    },
    autoSlide: {
      type: Boolean,
      default: true,
    },
    speed: {
      type: Number,
      default: 30,
    }
  },
  data() {
    return {
      loopThread: null,
      slides: [
        {
          name: 'Nettverk',
          component: 'Network'
        },
        {
          name: 'Video',
          component: 'VideoHd'
        },
        {
          name: 'Lyd',
          component: 'Sound'
        },
        {
          name: 'Animasjon',
          component: 'Animations'
        },
        {
          name: 'Trasè skjerm',
          component: 'RouteProgressDummy'
        },
        {
          name: 'Info skjerm',
          component: 'InfoScreenDummy'
        }]
    }
  },
  watch: {
    autoSlide: function (val) {
      if (val)
        this.startSliderLoopThread()
      else
        clearInterval(this.loopThread)
    },
    speed: function () {
      this.startSliderLoopThread() // basically restart to change speed
    }
  },
  beforeDestroy() {
    clearInterval(this.loopThread)
  },
  mounted() {
    this.startSliderLoopThread()
  },
  methods: {
    startSliderLoopThread() {
      if (!this.autoSlide)
        return
      if (this.loopThread)
        clearInterval(this.loopThread)
      this.loopThread = setInterval(async () => {
        await this.incrementIndex(false)
      }, this.speed * 1000)
    },
    async incrementIndex(resetInterval = true) {
      if (resetInterval)
        this.startSliderLoopThread()
      let nextIndex = this.slideIndex >= this.slides.length - 1 ? 0 : this.slideIndex + 1
      await this.$router.push({query: {...this.$route.query, index: nextIndex}})
    },
    async decrementIndex() {
      this.startSliderLoopThread()
      let nextIndex = this.slideIndex <= 0 ? this.slides.length - 1 : this.slideIndex - 1
      await this.$router.push({query: {...this.$route.query, index: nextIndex}})
    },
    async toggleAutoSlide() {
      await this.$router.push({query: {...this.$route.query, auto: !this.autoSlide}})
    },
    async changeSlideTransitionSpeed(event) {
      console.log(event)
      await this.$router.push({query: {...this.$route.query, speed: event.target.value}})
    }
  }
}
</script>

<style scoped>
.demo-screen {
  height: 100%;
}

.nav {
  background-color: rgba(64, 64, 64, 0.4);
  color: whitesmoke;
  position: absolute;
  bottom: 4rem;
  left: 4rem;
  padding: 1rem;
}
</style>
