<template>
  <div class="route-header">
    <div class="route-indicator">{{ line }}</div>

    <div class="route-name-container">
      <div :class="['route-name', { long: routeNameLong, breakline: breakLine }]">{{ routeName }}</div>
      <div v-if="breakLine" style="width: 100%"></div>
      <div v-if="routeVia" :class="['route-via', { long: viaTextLong, breakline: breakLine }]">{{ routeVia.includes('via') ? routeVia : `via ${routeVia}` }}</div>
    </div>

    <clock class="route-clock"></clock>
  </div>
</template>

<script>
import Clock from './Clock'
import { getWidthOfText } from "@/utils/utilMisc";

export default {
  name: 'RouteInfo',
  components: { Clock },
  props: {
    routeName: {
      type: String,
      required: true,
    },
    line: {
      type: String,
      required: true,
    },
    routeVia: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      routeNameLong: false,
      viaTextLong: false,
      breakLine: false,
      updateTimer: 0,
    }
  },
  computed: {
  },
  mounted() {
    this.updateLayout()
    this.updateTimer = setInterval(this.updateLayout, 1000)
  },
  beforeDestroy() {
    clearInterval(this.updateTimer)
  },
  watch: {
    routeName: function (newName, oldName) {
      if (oldName !== newName) {
        this.resetLayout()
      }
      this.updateLayout()
    },
    line: function (newName, oldName) {
      if (oldName !== newName) {
        this.resetLayout()
      }
      this.updateLayout()
    },
    routeVia: function (newName, oldName) {
      if (oldName !== newName) {
        this.resetLayout()
      }
      this.updateLayout()
    },
  },
  methods: {
    updateLayout() {
      const isWideScreen = (document.documentElement.clientWidth <= 960)

      // Adjust route container width
      const header = document.querySelector(".route-name-container")
      const routeClock = document.querySelector(".route-clock")
      const breakLineWidth = (routeClock.getBoundingClientRect().left - header.getBoundingClientRect().left - 1)
      header.style.width = `${breakLineWidth}px`

      // Determine if we should break the via text to the next line
      // and if the route or via text is too long
      const nameTextWide = (getWidthOfText(this.routeName, 'PostGrotesk', isWideScreen ? '4vw' : '6.7vw') + 45)
      const viaTextWide = this.routeVia ? getWidthOfText(this.routeVia, 'PostGrotesk', isWideScreen ? '3vw' : '5.5vw') : 0
      const combinedTextWide = Math.ceil(nameTextWide + viaTextWide)

      this.breakLine = this.routeVia ? (combinedTextWide >= breakLineWidth) : false
      this.routeNameLong = (nameTextWide >= breakLineWidth)
      this.viaTextLong = this.routeVia && (viaTextWide >= breakLineWidth)
    },
    resetLayout() {
      this.routeNameLong = false
      this.viaTextLong = false
      this.breakLine = false
    },
  },
}
</script>

<style scoped>
html {
    -moz-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
}

.route-header {
  padding: 4.63vmin 4.16vw;
  background-color: #fafafa;
  display: flex;
  align-items: center;
  flex: 0 1 auto;
}

.route-indicator {
  font-size: 6.67vw;
  font-weight: bold;
  background: #000000;
  border-radius: 1.5vw;
  padding: 0 2.2vw;
  line-height: 15.8vmin;
}

.route-name-container {
  margin-left: 3.3333vw;
  max-height: 15.7vmin;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  align-content: center;
  flex-wrap: wrap;
  overflow-x: visible;
}

.route-name {
  font-size: 6.67vw;
  font-weight: bold;
  line-height: 15.8vmin;
  white-space: normal;
  color: #000000;
  background-color: #fafafa;
  margin-right: 2.0833vw;
  display: inline-block;
  z-index: 5;
}

.route-name.breakline {
  font-size: 5.208vw;
  line-height: 11.3vmin;
}

.route-name.long {
  font-size: 3.594vw;  
  line-height: 8.6vmin;
}

.route-via {
  font-size: 5.2vw;
  line-height: 12.3vmin;
  white-space: nowrap;
  color: #5a5a5a;
  display: inline-block;
  z-index: 5;
}

.route-via.breakline {
  font-size: 4.167vw;
  line-height: 8.8vmin;
}

.route-via.long {
  font-size: 3.2vw;
  line-height: 7.4vmin;
  padding-top: 5px;
}

.route-clock {
  font-size: 5.417vw;
  font-weight: bold;
  line-height: 25vmin;
  color: #000000;  
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 4.16vw;
  z-index: 500;
}

@media (min-aspect-ratio: 30/9) {
  .route-indicator {
    font-size: 3.75vw;
    border-radius: 0.84vw;
    padding: 0 1.24vw;
  }

  .route-name {
    font-size: 3.75vw;
  }

  .route-name.long {
    font-size: 2.25vw;
  }

  .route-name.breakline {
    font-size: 2.9vw;
  }

  .route-via {
    font-size: 2.9vw;
  }

  .route-via.long {
    font-size: 1.29vw;
  }
    
  .route-via.breakline {
    font-size: 2.34vw;
  }

  .route-clock {
    font-size: 3.75vw;
  }
}
</style>