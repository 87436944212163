<template>
  <div class="notification" :class="{ warning: msgType === 'Warning', info: msgType === 'Information' }">
    <div style="position: relative;">
      <div class="icon-wrapper">
        <div v-if="svgIcon" class="svg-container">
          <svg v-if="msgType === 'Warning'" viewBox="0 0 90 77" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M50.3619 5.17855L86.1778 64.9979C88.672 69.1638 85.671 74.4585 80.8155 74.4585H9.1837C4.32822 74.4585 1.32714 69.1638 3.8214 64.9979L39.6373 5.17856C42.0637 1.12603 47.9355 1.12603 50.3619 5.17855Z"
                stroke="black" stroke-width="4.16667"/>
            <path
                d="M45.0009 58.8335C47.6172 58.8335 49.1676 60.6689 49.1676 62.9506C49.1676 65.4804 47.4719 67.1669 45.0009 67.1669C42.53 67.1669 40.8343 65.4804 40.8343 62.9506C40.8343 60.6689 42.3847 58.8335 45.0009 58.8335Z"
                fill="black"/>
            <path d="M49.1672 21.3334L40.8339 21.3334L40.8339 54.6667L49.1672 54.6667L49.1672 21.3334Z" fill="black"/>
          </svg>
          <svg v-else viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2a10 10 0 1 0 10 10A10.016 10.016 0 0 0 12 2Zm0 15a1 1 0 1 1 1-1 1 1 0 0 1-1 1Zm1-5a1 1 0 0 1-2 0V8a1 1 0 0 1 2 0Z"/>
          </svg>
        </div>
      </div>

      <slot name="default">
        <img v-if="iconPath" :src="iconPath" alt=""/> {{ msg }}
      </slot>
      <div class="gradient-left"></div>
      <div class="gradient-right"></div>
    </div>


  </div>
</template>

<script>
export default {
  name: "Notification",
  props: {
    iconPath: {
      type: String,
      required: false,
    },
    msg: {
      type: String,
      required: false
    },
    msgType: {
      type: String,
      required: false,
      default: () => 'Warning'
    },
    svgIcon: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      slide: null,
    }
  },
}
</script>

<style lang="scss" scoped>

.notification {
  position: absolute;
  z-index: 401;
  bottom: 0;
  width: 100%;
  background-color: #000000;
  padding: 3.4vmin 4.16vw;
  font-size: 5vw;
  font-weight: 400;
  line-height: 6.67vw;
  border-top: 2px solid #cccccc;

  &.warning {
    color: #f6bc00;
    fill: currentColor;
  }

  &.info {
    color: #5DC662;
    fill: currentColor;
  }

  .icon-wrapper {
    position: absolute;
    left: -3px;
    z-index: 100;
    height: 100%;
    background-color: black;
    padding: 20px 0;

    .svg-container {
      width: 4.69vw;
      height: 7.1vmin;

      svg {
        width: 100%;
        height: 100%;
        margin-right: 2vw;
        vertical-align: top;
      }
    }
  }

  img {
    width: 3.6vw;
    height: 6.4vmin;
    padding-right: 1.5vw;
    padding-left: .5vw;
  }

  .gradient-left {
    background: linear-gradient(270deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    transform: rotate(-180deg);
    width: 4.16vw;
    position: absolute;
    z-index: 10;
    left: 4.5vw;
    top: 0;
    bottom: 0;
  }

  .gradient-right {
    background: linear-gradient(270deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    width: 9.4vw;
    position: absolute;
    z-index: 10;
    right: 4.16vw;
    top: 0;
    bottom: 0;
  }
}

@media (min-aspect-ratio: 30/9) {
  .notification {
    font-size: 2.8vw;
    line-height: 3.75vw;

    .svg-container {
      width: 2.6vw;
    }
  }
}

</style>
