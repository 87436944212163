<template>
  <div class="route-progress-dummy">
    <iframe v-if="sampleVehicle"
            :src="`${kioskFrontendUrl}/#/mac/${sampleVehicle.id}?testing=true`"
            style="width: 100%; height: 100%;">
    </iframe>
  </div>
</template>

<script>
import {logger} from "@/utils/logger";

export default {
  name: "RouteProgressDummy",
  data() {
    return {
      sampleVehicle: null,
      kioskFrontendUrl: process.env.VUE_APP_DEMO_KIOSK_FRONTEND_URL,
      environment: process.env.VUE_APP_ENV,
    }
  },
  mounted() {
    this.getRandomScreenDeviceCurrentlyOnTrip()
  },
  methods: {
    async getRandomScreenDeviceCurrentlyOnTrip() {
      try {
        const kioskFaHost = `https://sanntidng-kiosk-fa-${this.environment === 'local'? 'dev' : this.environment}.azurewebsites.net`
        const response = await this.$http.get(`${kioskFaHost}/api/screendevice/all`)
        let routeScreenDevicesWithSound = response.data
            .filter(entry => entry.tripId > 0)
            .reduce((acc, entry) => acc.concat(entry.screenDevices), [])
            .filter(entry => entry.displayType === 'traseScreen' && entry.amplifier === true)
        let rand = Math.floor(Math.random() * routeScreenDevicesWithSound.length)
        this.sampleVehicle = routeScreenDevicesWithSound[rand]

      } catch (error) {
        logger.error('Failed to get list of active vehicles (ListVehicleStates)', error)
        alert('Kunne ikke laste liste med kjøretøy på tur')
      }
    },
  }
}
</script>

<style scoped>
.route-progress-dummy {
  height: 100%;
}

iframe {
  border: none;
}
</style>
