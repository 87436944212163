<template>
  <div> {{ hours }}:{{ minutes }} </div>
</template>

<script>
export default {
  name: 'Clock',
  data() {
    return {
      hours: 0,
      minutes: 0,
      interval: 0,
    }
  },
  mounted() {
    this.updateClock()
    this.interval = setInterval(this.updateClock, 1000)
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },

  methods: {
    updateClock() {
      const date = new Date()
      this.hours = date.getHours()
      this.minutes = date.getMinutes()
      this.minutes = this.minutes < 10 ? '0' + this.minutes : this.minutes
      this.hours = this.hours < 10 ? '0' + this.hours : this.hours
    },
  },
}
</script>

<style scoped></style>
