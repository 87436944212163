<template>
  <div class="box">
    <div :class="['box-content', boxClasses.boxSize, boxClasses.fontSize]">
      {{ boxText }}
    </div>
  </div>
</template>

<script>
import {getWidthOfTextInElementInVw} from "@/utils/utilMisc";

export default {
  name: "BoxesWithText",
  data() {
    return {
      boxSizeThread: null,
      fontSizeThread: null,
      boxText: 'En box med variasjon i størrelsen',
      boxClasses: {
        boxSize: 'small',
        fontSize: 'font-dense'
      },
    }
  },
  beforeDestroy() {
    clearInterval(this.boxSizeThread)
    clearInterval(this.fontSizeThread)
  },
  mounted() {
    this.redrawBox()
  },
  methods: {
    redrawBox() {
      this.boxSizeThread = setInterval(async () => {
        await this.boxSizeRandomize()
      }, 6 * 1000)
      this.fontSizeThread = setInterval(async () => {
        await this.fontSizeRandomize()
      }, 2 * 1000)
    },
    boxSizeRandomize() {
      setTimeout(() => {
        this.boxClasses.boxSize = ['small', 'medium', 'large'][this.getRandomNumber(3)]
      }, this.getRandomNumber(500))
    },
    fontSizeRandomize() {
      this.boxClasses.fontSize = ['font-dense', 'font-normal', 'font-wide'][this.getRandomNumber(3)]
      setTimeout(() => {
        let textLength = getWidthOfTextInElementInVw('.box-content', this.boxText)
        let containerWidth = this.getWidthOfElement('.box-content')
        if (textLength > containerWidth)
          this.boxClasses.fontSize = 'font-dense'
      }, 500)
    },
    getRandomNumber(max) {
      return Math.floor(Math.random() * max)
    },
    getWidthOfElement(cssClass) {
      let containerWidth = Math.max(document.querySelector(cssClass).clientWidth, 1)
      let viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
      return (100 * containerWidth) / viewportWidth
    }
  },
}
</script>

<style scoped lang="scss">
.box {
  margin-top: 3em;
  color: black;
  text-align: center;

  .small {
    width: 33%;
    height: 100px;
    background-color: lightgoldenrodyellow;
  }

  .medium {
    width: 66%;
    height: 200px;
    background-color: lightyellow;
  }

  .large {
    width: 99%;
    height: 300px;
    background-color: lightcyan;
  }

  .font-dense {
    font-size: 1.16vw;
    line-height: 8.8vmin;
  }

  .font-normal {
    font-size: 3.2vw;
    line-height: 12.3vmin;
  }

  .font-wide {
    font-size: 5.67vw;
    font-weight: bold;
    line-height: 15.8vmin;
  }
}
</style>
