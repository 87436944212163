import Vue from 'vue'
import VueRouter from 'vue-router'
import { toBoolean } from '@/utils/utilMisc'
import Boot from '@/views/Boot'
import DemoScreen from '@/views/demo-screen/Demo-screen'
import Home from '@/views/Home'
import MarketingContainer from '@/views/marketing/MarketingContainer'
import SoundTest from '@/views/SoundTest'
import VehicleRouteProgress from '@/views/VehicleRouteProgress'
import VesselRouteProgress from '@/views/VesselRouteProgress'
import VehicleWideScreen from '@/views/VehicleWideScreen'

Vue.use(VueRouter)

const demoStaticWebAppHostName = 'demo.kiosk.kolumbus.no'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: window.location.hostname === demoStaticWebAppHostName ? DemoScreen : Home,
    props: route => (
        window.location.hostname === demoStaticWebAppHostName ?
            { slideIndex: route.query.index ? Number.parseInt(route.query.index) : 0, autoSlide: route.query.auto ? toBoolean(route.query.auto) : true }
            :
            {}
    ),
  },
  {
    path: '/mac/:mac',
    name: 'Boot',
    component: Boot,
    props: route => ({ mac: route.params.mac, testing: toBoolean(route.query.testing), reload: route.query.reload ? toBoolean(route.query.reload) : undefined }),
  },
  {
    path: '/trase/vehicle/:vehicleId',
    name: 'VehicleRouteProgress',
    component: VehicleRouteProgress,
    props: route => ({ vehicleId: parseInt(route.params.vehicleId), testing: toBoolean(route.query.testing) }),
  },
  {
    path: '/trase/vessel/vehicle/:vehicleId',
    name: 'VesselRouteProgressVehicle',
    component: VesselRouteProgress,
    props: route => ({ vehicleId: parseInt(route.params.vehicleId), testing: toBoolean(route.query.testing) }),
  },
  {
    path: '/trase/vessel/mac/:mac',
    name: 'VesselRouteProgress',
    component: VesselRouteProgress,
    props: route => ({ mac: route.params.mac, testing: toBoolean(route.query.testing) }),
  },
  {
    path: '/trase/mac/:mac',
    name: 'VehicleRouteProgressMac',
    component: VehicleRouteProgress,
    props: route => ({ mac: route.params.mac, vehicleId: parseInt(route.params.vehicleId), testing: toBoolean(route.query.testing) }),
  },
  {
    path: '/trase/wide/:mac',
    name: 'VehicleWideScreenMac',
    component: VehicleWideScreen,
    props: route => ({ mac: route.params.mac, vehicleId: parseInt(route.params.vehicleId), testing: toBoolean(route.query.testing) }),
  },
  {
    path: '/soundtest',
    name: 'SoundTest',
    component: SoundTest,
  },
  {
    path: '/admin',
    name: 'Admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin.vue'),
  },
  {
    path: '/marketing/vehicle/:vehicleId',
    component: MarketingContainer,
    props: route => ({ vehicleId: parseInt(route.params.vehicleId), testing: toBoolean(route.query.testing) }),
  },
  {
    path: '/marketing/mac/:mac',
    component: MarketingContainer,
    name: 'MarketingMac',
    props: route => ({ mac: route.params.mac, testing: toBoolean(route.query.testing) }),
  },
  {
    path: '/demo-screen',
    component: DemoScreen,
    props: route => (
        {
          slideIndex: route.query.index ? Number.parseInt(route.query.index) : 0,
          autoSlide: route.query.auto ? toBoolean(route.query.auto) : true,
          speed: route.query.speed && !isNaN(parseInt(route.query.speed, 10)) ? Number.parseInt(route.query.speed) : 30,
        }),
  }
]

const router = new VueRouter({
  routes,
})

export default router
